a {
	color: @link-color;
	text-decoration: none;
}

a{
 	.transition(color, 300ms);
}
a:hover{
	color:@brand-secondary;
}
a:focus{
	outline-color: @brand-secondary;
	outline-width: 5px;
}