
@import "products-grids/list-not-cropped.less";
@import "products-grids/thumbnails.less";
@import "products-grids/block-not-cropped-no-captions.less";
@import "products-grids/list-cart-not-cropped.less";
@import "products-grids/block-not-cropped.less";
@import "products-grids/list-cart.less";



.items(@n){
width: 100% / @n;
float: left;
display: block;
.clearfix();
}

//  ##########################  GROS COUP DE CLEAN A FAIRE   #############################################

.product-list{
 .container(fluid);
}
.product-simple-list{
.list-not-cropped();
}

.product-block-list{ 
	.block-not-cropped();
}


