.stories-sidebar {
  .item {
    .items(4);
    padding: 0 @simple-padding 0 0;
    h4 {
      font-size: 14px;
      line-height: 15px;
      margin: 0 0 20px 0;
      padding: 0;
      font-weight: normal;
      height: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        &:hover {
          text-decoration: none;
          color: @brand-primary;
        }
      }
    }
    &.first {
      margin-left: 0
    }
    .framed {
      height: 194px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-bottom: 50px;
      a {
        display: block;
        height: 100%;
      }
    }
  }
  .stories-more {
    clear: both;
    border-top: 1px solid #f2f2f2;
    margin-top: 30px;
    padding-top: 37px;
    a {
      float: right;
      color: @brand-primary;
      font-size: 16px;
      font-family: 'avalon-plain';
      font-weight: bold;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.search-widget {
  margin-top: 1.5rem;
  & .select-pages {
    padding-bottom: 4rem;
    border-bottom: 2px solid #dfdfdf;
    & input {
      width:inherit;
      display: inline-block;
      line-height: 4rem;
      margin: 0;
      color: #111;
      margin-right: 0.8rem;
      cursor: pointer;
    }
    & label {
      width:inherit;
      display: inline-block;
      float: none;
      line-height: 4rem;
      vertical-align: top;
      margin-right: 3.5rem;
      cursor: pointer;
    }

    & input[type='radio']:after {
        width: 12px;
        height: 12px;
        border-radius: 15px;
        top: 1px;
        left: 0;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
    }

    & input[type='radio']:checked:after {
        width: 12px;
        height: 12px;
        border-radius: 15px;
        top: 1px;
        left: 0;
        position: relative;
        background-color: @brand-secondary;
        content: '';
        display: inline-block;
        visibility: visible;
    }

    input:focus,input:active {
      .box-shadow(none);
    }

  }
}
