//////// VIDEO PLAYER //////////

.video-js .vjs-big-play-button::before, .video-js .vjs-play-control::before, .vjs-icon-play::before {
  content: "\f101" !important;
}
.video-js .vjs-fullscreen-control:before, .vjs-icon-fullscreen-enter:before {
  content: "\f108" !important;
}
.video-js .vjs-mute-control:before, .video-js .vjs-volume-menu-button:before, .vjs-icon-volume-high:before {
  content: "\f107" !important;
}

.video-js{
  & .vjs-control:before {
    font-size: 1.8em;
    line-height: 3;
  }

  & .vjs-time-control {
    line-height: 5em;
  }
  & .vjs-tech {
    position: relative;
  }
  & .vjs-control-bar {
    height: 5.4em;
    background-color: #000;
    background-color: rgba(0,0,0,.7);

    position: relative;
    bottom:5.4em;
    max-width: 1089px;
    margin: 0 auto;
  }

  & .vjs-big-play-button {
    font-size: 6em;
    line-height: 1.5em;
    height: 1.5em;
    width: 1.5em;
    border: 0.06666em solid white;
    border-radius: 0.3em;
    left: 50%;
    top: 50%;
    margin-left: -0.75em;
    margin-top:  -0.75em;
  }
  & .vjs-menu-button-inline .vjs-menu {
    padding-top: 1.2em;
  }

  & .vjs-remaining-time-display{
    line-height: 5.42em;
  }

  & .vjs-play-progress {
    background-color: @brand-primary;
  }

  & .vjs-volume-level {
    background-color: @brand-primary;
  }

  & .vjs-slider-horizontal .vjs-volume-level {
    height: .4em;
  }
}