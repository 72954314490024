
.block-not-cropped{
  padding: @double-padding 0 @double-padding 0;
  & li {      
    position: relative; 
    list-style: none;    
    z-index: 0;
    @item-gutter: 15px;
    padding-left: @item-gutter;
    padding-bottom: @item-gutter;

    .items(@items-default);
    @media @xlarge{.items(@items-xlarge);}
    @media @large{.items(@items-large);}
    @media @desktop{.items(@items-desktop);}
    @media @tablet{.items(@items-tablet);}
    @media @mobile{.items(@items-mobile);}
    .border-radius();
    
    & .item-paginate {
      margin-top: 1px;
      margin-left: -1px;
      height: @item-height + 5px;
      display: table;
      padding: @double-padding; 
      background-color: transparent !important;  
      outline: 1px solid @blocks-paginate-outline;
      
      & a {
        display: block;
        position: relative;
        height: 100%;
        text-align: left;
        vertical-align: middle;
        display: table-cell;
        .font(22);
        color: @brand-secondary;
        
        &:hover{
          text-decoration: none;
        }
      }
    }
    
    & .item{     
      outline: 1px solid @blocks-outline; 
      width: 100%;
      box-sizing: border-box;
      height: @item-height;
      position: relative;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
      border-bottom: 3px solid transparent;

      & .image {
        .border-radius();
        display: table;
        position: relative;
        width: 101%;
        background-color: @thumbnail-background-color;
        @media @desktop, @tablet {height: 140px; line-height: 140px; max-height: 140px;};
        @media @mobile {height: 160px; line-height: 160px; max-height: 160px;};
        max-height: 150px;
        height: 150px;
        & a{
          display: table-cell;
          position: relative;
          cursor: pointer;
          width: inherit;
          text-align: center;
          vertical-align: middle;
          height: inherit;
          line-height: inherit;
          max-height: inherit;
          .border-radius();
          & .icon-only{
            width: 100%;
            height: 100%;
            background-color: @soft-border-color;
            line-height: inherit;
            padding: 0;
            & span{
              .file-icon-large();
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }


      & .caption{
        padding: 15px 15px 0 15px;
        @media @mobile {
          padding-top: 5px;
        }

          .media-title{
            display: block;
            vertical-align: top;
            width: 100%;
            max-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;

            & a{
              .font(14);
              font-weight: 300;
              color: @font-darker-color;
              // text-transform: uppercase;
            }
          }
          & .extra-fields{
            width: 100%;
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            & p{
              .font(11);
              margin: 0;
              color: #A2A2A2;
            }
          }
      }

      & .actions {
        position: absolute;
        width:100%;
        margin: 0 auto;
        bottom: 0;
        & a{
            width: 100%;
            height: 100%;
            display: block;
          }
        & .bnt-wrapper{
          vertical-align: middle;
          width: 100%;
          text-align: center;
            & .item-btn {
              width: 30px;
              float: none;
              display: inline-block;
              font-size: 22px;
              color: #4a63ae;
              background-color: white;
              border: 0;
              height: 30px;
              border-radius: 15px;
              padding: 0;
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.29);
              margin: 10px;
              & p {
                display: none;
              }
              &:hover {
                color: @font-ultralight-color !important;
                background-color: #4a63ae !important;
              }

            }
          }
        }
      
      & .selector-container{
        visibility: hidden;
        position: absolute;
        display:block;
        z-index: 11;
        padding: 5px;
        left: 0;
        top: 0;
      }    
    }  
  }
}
 
// ############### ANIMATIONS & BEHAVIOURS ###############  //
.block-not-cropped{
  li{    
    &:hover{
      z-index: 0;

      & .item-paginate{
      outline: 1px solid @brand-primary;
        & a{
          color: @brand-primary;
        }
      }
      & .item{
        color: @font-ultralight-color;
        border-bottom: 3px solid @brand-secondary;

        & a {
          cursor: pointer;
        }
        & .image{
          & .thumbnail-wrapper{
            &:after {
              opacity: 0.5;
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              background-color: #003871;
            }
          }
          

        }

        & .caption{
          & .media-title{
            & a {
              color: @brand-primary;
            }
          }
        }

        & .actions{
          & .bnt-wrapper {
            display: inline-block;
          }
        }
      }

      .selector-container{
         visibility: visible;
        z-index: 20;
        
        & input { 
        display: inline-block;
        line-height: 10px; 
        .icon-checkbox_off();
        z-index: 999;
      
          &:before{
            color:#ffffff;    
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding: 0;
          }
        }
      }

    }

///END HOVER


    &.selected {
      z-index: 0;

      a {
        color: @brand-primary;
        cursor: pointer;
      }
      & .item {
        border-bottom: 3px solid @brand-secondary;
      }
      & .image{
        & .thumbnail-wrapper{
          &:after {
            opacity: 0.5;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: #003871;
          }
        }
      }

      & .caption{
        & .media-title{
          & a {
            color: @brand-primary;
          }
        }
      }
      & .selector-container{
        visibility: visible;
        z-index: 20;
        
        & input[type="checkbox"]~label:before{
        content: @holly-check;
        }
      }   
    }  
  }
}
