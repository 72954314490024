@body-min-width: none;

html, body {
	margin: 0;
	border: none;
	font-family: @font-family-base;
	font-weight: 100;
	font-size: 62.5%;
	min-width: @body-min-width;
}

body{
	background: #eeeeee;
	margin: 0 @simple-padding !important;
	& > .container{
		position: relative;
		margin: 0 auto;
		min-height: 550px;
		border-top: 1px solid #d8d8d8;
		margin-top: 3px;
		max-width: 1400px;
		padding-bottom: 100px;
	}
}

hr {
  margin-top:    @double-padding;
  margin-bottom: @double-padding;
  border: 0;
  border-top: 1px solid @separator-color;
}
