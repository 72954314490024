/**
 * Feed
 */


.paginate {
  float: left;
  padding: 0;
  // margin: 0 auto 30px auto;
  a {
    text-transform: capitalize;
    bottom: 0;
    right: 0;
    background: @brand-primary;
    color: #fff;
    padding: 15px 20px;
    display: block;
    float: right;
    margin-right: -8px;
    &:hover {
      text-decoration: none;
    }
  }
}

#feeds-menu {
  margin: -15px -15px 115px -15px;
  a.first-entry {
    background: url(../img/bgd_nav_feed_first.png) right 0 no-repeat;
    color: #333a40;
    padding-right: 25px;
    padding-left: 20px;
    position: relative;
    z-index: 900;
    margin-right: -15px;
    float: left;
    display: block;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
  }
  ul {
    margin: 0;
    padding: 0;
    height: 40px;
    line-height: 40px;
    list-style-type: none;
    vertical-align: middle;
    background: #f5f7f5;
    li {
      float: left;
      &.current,
      &:hover {
        a {
          background: @brand-primary;
          text-decoration: none;
          color: #fff;
        }
      }
      a {
        display: block;
        color: #808080;
        background: #f5f7f5;
        padding: 0 20px;
        &.margin {
          padding-left: 30px;
        }
      }
    }
  }
  .feeds-menu-detail {
    margin-bottom: 95px;
  }
}

.feeds-entry {
  width: 90%;
  font-family: @font-family-sans-serif;
  margin: 0 auto 30px auto;
  border: 1px solid #e6e6e6;
  padding-left: 0;
  float:left;
  &:after {
    clear: both;
  }
  .content {
    h2{
      font-size: 26px;
      margin:30px 30px 15px 30px;
    }
    h1 {
      margin: 20px 0 0 0;
      font-size: 32px;
      line-height: 40px;
      color: @gray-darker;
      padding: 0 0 0 0;
      font-weight: 300;
      a {
        color: @font-base-color;
        font-size: 26px;
        line-height: 30px;
        font-family: 'avalon-plain';
        &:hover {
          color: @brand-primary;
          text-decoration: underline;
        }
      }
    }
    .author {
      font-size: 12px;
      line-height: 25px;
      margin: 0 0 20px 0;
      font-weight: 400;
      color: #58b2db;
      time, span {
        font-size: 13px;
        font-family: 'avalon-plain';
        color: #4a63ae;
      }
    }
    p {
      color: #808080;
      font-size: 14px;
      line-height: 24px;
      margin: 10px 0;
      display: none;
    }
    a.feed-link {
      display: none;
      position: absolute;
      bottom: 0;
      right: 0;
      background: @brand-primary;
      color: #fff;
      padding: 15px 20px;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .visual {
    float: left;
    margin-right: 30px;
    height: 170px;
    width: 170px;
    a {
      width: 100%;
      height: 100%;
      display: block;
      background-size: cover;
      background-position: center center;
    }
  }
  &.first {
    margin-bottom: 70px;
    border: 0;
    .content {
      h2 {
        margin: 0;
        font-size: 32px;
        line-height: 40px;
        a {
          font-size: 32px;
          line-height: 40px;
        }
      }
      .author {
        color: #b2b2b2;
      }
      p {
        display: block;
      }
    }
    .visual {
      height: 370px;
      width: 390px;
    }
  }
}
.feed-detail {
  & h1 {
    margin: 0;
    font-size: 48px;
    line-height: 52px;
    color: #4a63ae;
    padding: 0 0 @simple-padding;
    font-weight: normal;
  }
}
.author span, .author time {
  font-size: 13px;
  color: #b2b2b2;
}
.count {
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
  background: #b2bb1c;
  width: auto;
}

#medias {
  margin-top: @simple-padding;
  & .item {
    .items(@items-default);
    @media @xlarge{.items(@items-xlarge);}
    @media @large{.items(@items-large);}
    @media @desktop{.items(@items-desktop);}
    @media @tablet{.items(@items-tablet);}
    @media @mobile{.items(@items-mobile);}
    padding: 4px;
    & div {
      padding: 0;
      height: 200px;
      float: left;
      background-size: cover;
      background-position: center center;
      width: 100%;
    }
  }
}
