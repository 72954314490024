.thumbnail {
    position: relative;
    overflow: hidden;

    // Vertical + horizontal image centering
    display: table;
    width: 100%;
    .thumbnail-wrapper {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    &.cropped {
      display: block;
      .thumbnail-wrapper {
        margin: 0 auto; display: block; overflow:hidden;
      }
    }

    img {
        // .img-responsive(inline-block);
        max-height: 100%;
    }

    &.cropped img {
        height:auto!important;width:auto!important;max-width:none!important;max-height:none!important;
    }

    &.icon-only .file-icon {
        margin: auto;
        position: static;
    }
    .file-icon {
        display: block;
        position: absolute;
        right: 4px;
        bottom: 4px;
    }
}

.file-icon {
    .file-icon-position(14); // Default icon
}

.file-icon-jpg,
.file-icon-jpeg,
.file-icon-png,
.file-icon-gif,
.file-icon-tiff {
    .file-icon-position(0);
}
.file-icon-m4v,
.file-icon-mp4,
.file-icon-mpg,
.file-icon-mpeg,
.file-icon-ts,
.file-icon-mov,
.file-icon-wmv,
.file-icon-avi {
    .file-icon-position(1);
}
.file-icon-pdf {
    .file-icon-position(2);
}
.file-icon-mp3,
.file-icon-m4a,
.file-icon-ra,
.file-icon-wma,
 {
    .file-icon-position(3);
}
.file-icon-zip {
    .file-icon-position(4);
}
.file-icon-indd,
.file-icon-indl,
.file-icon-indt,
.file-icon-indb {
    .file-icon-position(5);
}
.file-icon-ai {
    .file-icon-position(6);
}
.file-icon-fla {
    .file-icon-position(7);
}
.file-icon-psd {
    .file-icon-position(8);
}
.file-icon-eps {
    .file-icon-position(9);
}
.file-icon-doc,
.file-icon-docx {
    .file-icon-position(10);
}
.file-icon-ppt,
.file-icon-pptx {
    .file-icon-position(11);
}
.file-icon-xls,
.file-icon-xlsx {
    .file-icon-position(12);
}
.file-icon-xls,
.file-icon-xlsx {
    .file-icon-position(14);
}

// .file-icon-xsmall {
//     @size: 16px;
//     .sprite-retina('../img/icons-16.png', '../img/icons-32.png', @size, @size, @size, auto);
// }
// .file-icon-small {
//     @size: 32px;
//     .sprite-retina('../img/icons-32.png', '../img/icons-64.png', @size, @size, @size, auto);
// }
// .file-icon-medium {
//     @size: 64px;
//     .sprite-retina('../img/icons-64.png', '../img/icons-128.png', @size, @size, @size, auto);
// }
// .file-icon-large {
//     @size: 128px;
//     .sprite-retina('../img/medias-icons-256.svg', '../img/icons-256.png', @size, @size, @size, auto);
// }


.file-icon-xsmall {
    @size: 16px;
    .sprite-retina('../img/icons-16.png', '../img/icons-32.png', @size, @size, @size, auto);
}
.file-icon-small {
    @size: 32px;
    .sprite-retina('../img/icons-32.png', '../img/icons-64.png', @size, @size, @size, auto);
}
.file-icon-medium {
    @size: 64px;
    .sprite-retina('../img/icons-64.png', '../img/icons-128.png', @size, @size, @size, auto);
}
.file-icon-large {
    @size: 128px;
    .sprite-retina('../img/medias-icons-256.svg', '../img/icons-256.png', @size, @size, @size, auto);
}

.file-icon-position(@index) {
    &.file-icon-xsmall {
        background-position: 0 (@index * -16px);
    }
    &.file-icon-small {
        background-position: 0 (@index * -32px);
    }
    &.file-icon-medium {
        background-position: 0 (@index * -64px);
    }
    &.file-icon-large {
        background-position: 0 (@index * -128px);
    }
}

.sprite-retina(@file-1x; @file-2x; @width-1x; @height-1x; @sprite-width-1x; @sprite-height-1x) {
  background: url("@{file-1x}") no-repeat;
  // .size(@width-1x, @height-1x);

  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    background-image: url("@{file-2x}");
    background-size: @sprite-width-1x @sprite-height-1x;
  }
}





