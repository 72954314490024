/**
 * Small modal
 */

// .paradeModalBackground{
// // width: 100%;
// // height: 100%;
// background-color: #ffffff;
// .opacity(0.9);
// position: fixed;
// top: 0;
// display:none;

// } 
.modal-body{
  & .container-fluid{
    .font(12);
  }
  & h2{
    color: @font-base-color;
  }
  & h3{
    margin: 0;
  }
  & .form-group {
    margin: 0;
  }
  & input, textarea{
    background-color: #ffffff;
    border-color: #DDDDDD;
    &:hover, :focus{
      background-color: #ffffff;
    }
  }
  & .message {
    margin-top: 0.5rem;
  }
  & input {
    margin-bottom: 0;
  }

}

.modal-dialog{
  // overflow: hidden;
  background-color: #ffffff;

}
.modal{
  & .large{

    // min-height: 500px;
    float:none;
    display: inline-block;
    // margin: 80px auto;
    // padding: @double-padding;
    // .drop-shadow();
    // .border-radius();

  }

}
.modal-dialog{
  // overflow: hidden;
  & #paraclose{
    top: @double-padding;
    right: @double-padding;
    position: absolute;
    z-index: 1;
  }
  width: 99%;
  float: none;
  vertical-align: middle;
  display: inline-block;
  padding: @double-padding;
  .drop-shadow();
  .border-radius();
}
.paradeModalSmall{
  .container(450);
  float: none;
  margin: auto;
}

.paradeModalLarge{
  .container(800);
  float: none;
  // margin: auto;
  // & .modal-dialog{
  //  .container(800);
  //  display: inline-block;
  // }
  .modal-content{
    display: inline-block;
    // overflow: hidden;

  }
}

.modal-body {
  position: relative;
}



.modal-open {
  overflow: hidden;
}

.modal {
  margin: auto;
  display: none;
  overflow: auto;
  // overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal;
  -webkit-overflow-scrolling: touch;
  &:before {
    display:inline-block;
    content: '';
    height: 100%;
    width:0;
    vertical-align:middle;
  }
  &.overlay:before {
    display: none;
  }
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    // .translate(0, -25%);
    // .transition-transform(~"0.3s ease-out");
  }
  &.in .modal-dialog { .translate(0, 0)}
}
.paradeModalBackground{
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal - 1;
  -webkit-overflow-scrolling: touch;

}

p.instructions{
  font-style: italic;
  color: @font-light-color;
  .font(11);
}


.submit-modal{

  margin-top: 45px;
}


.modal-header{
  & h2{
    margin: 5px 0 @double-padding 0;
  }

}