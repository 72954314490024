@holly-list_burger: "\e900";
@holly-more_rounded: "\e901";
@holly-more: "\e902";
@holly-folder_add: "\e903";
@holly-grid: "\e904";
@holly-search: "\e905";
@holly-zoom_in: "\e906";
@holly-zoom_out: "\e907";
@holly-cart: "\e908";
@holly-command: "\e909";
@holly-download: "\e90a";
@holly-folder: "\e90b";
@holly-share: "\e90c";
@holly-upload: "\e90d";
@holly-avatar: "\e90e";
@holly-fullscreen: "\e90f";
@holly-map: "\e910";
@holly-ring: "\e911";
@holly-rule: "\e912";
@holly-save: "\e913";
@holly-size: "\e914";
@holly-double_bold_left: "\e915";
@holly-double_bold_right: "\e916";
@holly-double_thin_left: "\e917";
@holly-double_thin_right: "\e918";
@holly-mail: "\e919";
@holly-rss: "\e91a";
@holly-link: "\e91b";
@holly-lock: "\e91c";
@holly-new_window: "\e91d";
@holly-send: "\e91e";
@holly-view: "\e91f";
@holly-warning: "\e920";
@holly-check: "\e921";
@holly-checkbox_off: "\e922";
@holly-checkbox_on: "\e923";
@holly-options: "\e924";
@holly-radio_off: "\e925";
@holly-radio_on: "\e926";
@holly-comment: "\e927";
@holly-down_bold: "\e928";
@holly-etc: "\e929";
@holly-left_bold: "\e92a";
@holly-right_bold: "\e92b";
@holly-up_bold: "\e92c";
@holly-close_bold: "\e92d";
@holly-close_thin: "\e92e";
@holly-delete: "\e92f";
@holly-down_thin: "\e930";
@holly-left_thin: "\e931";
@holly-right_thin: "\e932";
@holly-up_thin: "\e933";
@holly-calendar: "\e934";
@holly-rounded_left: "\e935";
@holly-rounded_right: "\e936";
@holly-taquet_both: "\e937";
@holly-taquet_down: "\e938";
@holly-taquet_up: "\e939";
@holly-time: "\e93a";
@holly-history: "\e93b";
@holly-infos: "\e93c";
@holly-pause: "\e93d";
@holly-play: "\e93e";
@holly-sound: "\e93f";
@holly-stats: "\e940";
@holly-edit: "\e941";
@holly-embed: "\e942";
@holly-settings: "\e943";
@holly-trash: "\e944";
