@import "section.library.filters.less";
//////////Layout///////////////
.filters-and-products{
	.row(12);
	border-top: 1px solid #d8d8d8;
	& aside.nav{
		@media @mobile, @tablet{
			.row(3);
		};
		@media @desktop{
			.row(3);
			padding: 0 20px;
		};
	}

}
.products{
 .row(9);
 @media @mobile, @tablet{
 	.row(9);
 };
 @media @desktop{
	.row(9);
};
 float: right;
 border-left: 1px solid #d8d8d8;
	margin-bottom: 70px;
 & .product-list{
 	display: table;
	min-height: 270px;
 }
}



@list-controller-height: 40px;
//////////BARRE DE BOUTONS///////////////

.list-controller{
.container(fluid, left,@list-controller-height);
line-height: @list-controller-height;
border-bottom: 1px solid #d8d8d8;
margin-top: -1px;
.clearfix();
}

.list-switch{
	display: inline-block;
	float: right;
	list-style: none;
	vertical-align: middle;
	line-height: inherit;
	height: inherit;
	& .label{
		float: left;
		display: inline-block;

		@media @mobile{
			display: none;
		};
		& p{
			.font(12);
			color: @font-base-color;
			display: inline-block;
			vertical-align: middle;
			padding: 0 @simple-padding;
			margin:0;
		}
	}  
	& li {
		display: inline-block;
		height: inherit;

		& a{
			display: inline-block;
			height: inherit;
			width: @list-controller-height;
			text-align: center;
            @media @mobile{
              width: 35px;
            };
			& span {
				display: inline-block;
				vertical-align: middle;
				line-height: inherit;
				height: inherit;
				font-size: 0;
				&:after{
				.font-size(30);
				display: inline-block;
				vertical-align: middle;
				line-height: inherit;
				}
			}
			
			
		}
		
	}
	& a.active {
		background-color: @brand-secondary;
		height: 100%;
		& span{
			&:after {
			color:#ffffff;
		}
		}
		
	}

}

.selection {
	display: inline-block;
	line-height: inherit;
	padding-left: @simple-padding;

	& label {
		&:before {
			position: relative;
			top: -4px;
		}
	}

	& input {
    width: 20px;
    height: @list-controller-height;
	line-height: @list-controller-height;
    display: inline-block;
    vertical-align: middle;
	}
	& .open>.dropdown-toggle {
		color: @font-ultralight-color;
		background-color: @brand-secondary;
	}
	& .dropdown {
	margin: 0 @simple-padding;
    height: @list-controller-height;
    display: inline-block;
    line-height: @list-controller-height;
	@media @mobile, @tablet{
		margin: 0;
	};
	    & a {
	    	line-height: inherit;
			padding-left: 10px;
			padding-right: 10px;
			&:hover {
				color: @font-ultralight-color;
				background-color: @brand-secondary;
			}
	    }
	}
}

.switch-to-product-block-list{
	& span{
		.icon-grid();	
	}
	
}

.switch-to-product-simple-list{
	& span{
		.icon-list_burger();	
	}
	
}


////////// No results ////////

#searchNoresult{
	padding-left: 30px;
	height: 500px;
	margin-bottom: 120px;
	padding-top: 200px;
	& h2 {
		color: @brand-secondary;
	}
}