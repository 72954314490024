@img-cart-height: 180px;
.cart-block-not-cropped{
  

  & li {      
    height: @img-cart-height;
    position: relative; 
    list-style: none;    
    z-index: 0;
    overflow-x: hidden;
    margin-bottom: @simple-padding;
    margin-right: @simple-padding;
    padding: 0;
    width:@img-cart-height;
    box-sizing: border-box;
    border: 3px solid @brand-second;
    overflow: hidden;

      &:hover{
        border: 3px solid @brand-primary;
      }


    & .item{
      box-sizing: border-box;
      width: 100%;
      background-color: #ffffff;
      width: 100%;
      background-color: #ffffff;
      box-sizing: border-box;
      position: relative;
      height: @img-cart-height;
      line-height:@img-cart-height;
      padding: 0;

      & .image {

        display: block;
        position: relative;
        width: @img-cart-height;
        height:@img-cart-height;
        line-height:@img-cart-height;
        background-color: @thumbnail-background-color;
          
        & a{    
          display: inline-block;
          position: relative;
          cursor: pointer;
          width: inherit;
          text-align: center;
          vertical-align: middle;
          height: inherit;
          line-height:@img-cart-height - 20px;
          & .icon-only{
            width: 100%;
            height: 100%;
            background-color: @soft-border-color;
            line-height: inherit;
            & span{
              display: inline-block;
              vertical-align: middle;
              line-height:inherit;
            }
          }
        }
      }

      & .caption{
          display: none;
      }

      & .actions  {
        display: none;
        position: absolute;
        bottom: 0;
        top: auto;
        right: 0;
        z-index: 99;
        background-color: transparent;
        height: 40px;
        width: 100%;
        
        & .bnt-wrapper{
          
          height: 40px;
          display: block;
          padding: 0;
          margin: 0;
          position: absolute;
          bottom: 0;
          right: 0;
          width: auto;


            & button {
              .btn-icon();
              width: 40px;
              height: 40px;
              margin-left: 2px;
              background-color: rgba(126,105,122,0.8);
              color: #ffffff;
              display: inline-block;
              text-align: center;
              vertical-align: middle;
              border: none;
              & p{
                      display:none;
              }
              &:after{
                  .font-size(20);
                  display: block;
                  margin: 0px 0 0 -6px;
              }
              &:hover{
              background-color: rgba(126,105,122,0.8);
              }

            }
          }
        }
      
      & .selector-container{
        visibility: hidden;
        position: absolute;
        display:none;
        padding: @simple-padding;
      }    
    }  
  }
}


// ############### ANIMATIONS & BEHAVIOURS ###############  //
.cart-block-not-cropped{
  li {    
      &:hover{
        z-index: 3; 
        & .item-paginate{
        outline: 1px solid @brand-primary;
        border: none !important;

          & a{
            color: @brand-primary;
          }
        
        }

        & .item{
          // border-bottom: 4px solid @brand-primary;
          // box-shadow: 0px 0px 10px hsla(0, 0%, 10%, .5);
          outline: 1px solid @brand-primary;
          a {color: @brand-primary;cursor: pointer;}
          & .caption{
            & .media-title{
              & a {
                color: @brand-primary;
              }
            }
          }
          & .actions{
            display: block;


          }
        }
        .selector-container{
          visibility: visible;
          z-index: 3;
        }
        .bnt-wrapper{
            display: block;  
            & .btn-icon:hover{
              background-color: rgba(126, 105, 122, 1);
              &:after{
                color: #ffffff;
              }
            }
        }
      }
    }


  .selected {
    border: 1px solid @brand-primary;
    .selector-container input {  
      .icon-checkbox_on();
    
    }
  }
}




