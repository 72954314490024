@footer-height : 100px;
footer {
  line-height: 50px;
  color: @font-ultralight-color;
  background-color: #555555;
  text-align: center;
  float: left;
  width: 100%;
  font-size: 13px;
  position: fixed;
  bottom:0;
  left: 0;
  & > .container{
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 15px;
  }

  .spacer {
    margin: 0 5px;
    display: inline-block;
  }

  a, span{
    color: @font-ultralight-color;
    text-decoration: underline;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.6px;

    &:hover,
    &:focus {
      color: @nav-link-hover-color;

    }
  }
  & .footer-onet {
    float: left;
    height: 30px;
    position: relative;
    top: 8px;
    margin-right: 25px;
  }
}