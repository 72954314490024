
.home{
		
	& .full{
		.row(9);
		float: right;
	}

	& .flux{
		& h2{padding: 20px 0;}
		.col(8);
		.push(1);

		@media @tablet, @mobile {.push(0);};

		.col(12,@tablet);
		.col(12,@mobile);
	}

	& .sidebar{
		margin-top: 100px;
		.col(4);
		.pull(1);

		@media @tablet, @mobile {
		.col(12);
		.pull(0);
		};
	}
	& .content-header{
		& h1 {
			display: none;
		}
		// }

	}
}