
//////////////////////// CLASSIC BREADCRUMBS ///////////////////////////


.breadcrumb{
	display: block;
	clear: both;
	padding: 0;
	padding-bottom: 50px;
	margin-bottom: 18px;
	list-style: none;
	background-color: transparent;
	border-radius: 0;

	&> .active {
		color: @brand-primary;
	}

	& >li+li:before {
		content: "|\00a0";
		padding-left: 10px;
		color: #333;
		display: inline-block;
		float: left;
	}
	& li{
		display: inline-block;
		font-size: 13px;
		vertical-align: top;
		& a {
		height: inherit;
		color: @font-base-color;
		float: left;
		}
	}
	
}


//////////////////////// FILTERS BREADCRUMB ///////////////////////////

.filters-breadcrumb{
	.row(12);
	& .filter-item{
	display: inline-block;
	position: relative;
	margin-bottom: 5px;
	.border-radius();
	background-color: @brand-second;
	line-height: 35px;
	padding-left: @simple-padding;
	margin-right: 3px;

		& p {
			display: inline-block;
			color: #ffffff;
			vertical-align: middle;
			margin:0;
		}
		& .filter-label{
			.font-size(12);
			font-weight: 300;
		}
		& .filter-value{
			margin-left: 5px;
			.font-size(14);
			font-weight: 700;
			text-transform: uppercase;
		}
		& #paraclose{
			display: inline-block;
			position: relative;
			float: none;
			vertical-align: middle;
			margin:0;
			height: 35px;
			font-size: 0;

			&:after{
			.font-size(12);
			color: #b3b3b3;
			display: inline-block;
    		vertical-align: middle;
			}
			&:hover{
				&:after{
				color: #ffffff;
			}
			}
		}
	}
}

