body.visual {
  .selection {
    padding-left: @simple-padding;
    height: 60px;
    line-height: 60px;
  }

  .intro {
    .col(3);
    margin-top: 30px;
    color: #808080;
    font-family: 'avalon-plain';
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 40px;
  }
  .products {
    .col(9);
    padding-left: 0;
  }
  /*ul*/
  .container-visual-docs {
    list-style: none;
    margin: 0;
    padding: 0;
    clear: both;
    /*li*/
    .document {
      color: @gray;
      position: relative;
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid @soft-border-color;
      &:first-child {
        border-top: 1px solid @soft-border-color;
      }
      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: #fff;
        background-color: @brand-primary;
        border-color: lighten(@brand-primary, 10%);
        .actions button {
          color: #fff;
        }
        .title p{
          color: #fff;
        }
      }
      &.selected {
        color: #fff;
        background-color: #a8b7e6;
        border-color: lighten(#a8b7e6, 10%);
        .actions button:hover {
          background-color: lighten(#a8b7e6, 10%);
        }
      }

      input[type="checkbox"] {
        font-size: 16px;
        height: 60px;
        margin: 0 8px;
      }

      .selector-container {
        line-height: 60px;
        display: inline-block;
        height: 60px;
        vertical-align: top;
        padding-left: 14px;
      }
      .thumbnail-icon {
        display: inline-block;
        height: 60px;
        margin: 0 50px;
        .file-icon {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .title {
        display: inline-block;
        line-height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        font-family: 'avalon-plain';
        vertical-align: top;
        & p {
          line-height: 60px;
          margin: 0;
        }
      }

      .actions {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        text-align: right;
        padding: 0;
        line-height: inherit;
        height: 100%;
        // top: 50%;
        // margin-top: -20px;
        button {
          color: lighten(@gray-light, 20%);
          background-color: transparent;
          float: left;
          & p {
            display:none;
          }
          &:hover {
            background-color: lighten(@brand-primary, 10%);
          }
          border: 0;
          padding: 0;
          width: 60px;
          line-height: 60px;
          height: 100%;
        }
      }
    }
  }
  & .pagination {
    float: none;
    margin:20px auto;
  }
}


.actions-and-products > .actions .btn {
  &:extend(.btn-block);
  max-width: 300px; // Else they can be huuuuge
  font-family: 'avalon-plain';
  font-size: 13px;
  color: #808080;
  // Spacing between buttons
  & + .btn {
    margin-top: 5px;
  }

  text-align: right;
  padding: 16px 16px 14px 16px;

  // Align glyphicons
  .glyphicon {
    float: left;
    font-size: 16px;
  }
}