// ################## Carousel Container ###########################
#carouselWrapper {
  .col(12);
  margin-top: 5rem;
  // padding-top: 160px; /// Content header height : Quick fix

}
// ################## Carousel [descriptions] style ###########################
.description_wrapper{

}

#descriptions{
  display: none;

  height:80px;
  background-color: rgba(255,255,255,0.7);
  -ms-filter:"progid:DXImageTransform.Microsoft.gradient(startColorstr=#70FFFFFF,endColorstr=#70FFFFFF)";
  position: relative;
  bottom: 80px;
  z-index: 1000;
  margin: 0;
  padding: 0;
  float: left;
  width: 100%;

  .title{
    display:table;
    height: 70px;
    margin: 0 auto;
    & a{
      display:table-cell;
      color: @brand-primary;
      font-size: 24px;
      text-align: center;
      vertical-align: middle;
    }

    & a:hover{text-decoration: none;}
  }

  .slick-slide{
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: relative;
  }
  .slick-active{
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transition: opacity 2s ease;
    -o-transition: opacity 2s ease;
    -moz-transition: opacity 2s ease;
    -ms-transition: opacity 2s ease;
  }
}

.slick-active{
  //margin-left: -15px; // Correct some alignement issues due to Bootsrap grid (quick and dirty)
  margin-left: 15px;
  & p{
    display: table-cell;
    vertical-align: middle;
    height: 50px;
    max-width: 490px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}


// ################## Carousel [images] style ###########################

#carousel {
  height: 445px;
  vertical-align: middle;

  .slick-slide{opacity: 0.15; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";margin-left: 2px;}
  .slick-active{
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transition: opacity 2s ease;
    -o-transition: opacity 2s ease;
    -moz-transition: opacity 2s ease;
    -ms-transition: opacity 2s ease;
  }

  img {
    display: block;
    min-height: 445px;
    max-height: 445px;
    width: auto;
    height: auto;
  }
  .slick-next, .slick-prev {
    margin-top: 0;
    width: 105px;
    min-width: 50px !important;
    height: 445px;
    display: block;
    transition: width 0.2s ease;
    -webkit-transition: width 0.2s ease;
    -o-transition: width 0.2s ease;
    -moz-transition: width 0.2s ease;
    -ms-transition: width 0.2s ease;
    z-index: 1;
  }
  .slick-next {
    right: 0;
    background-position: 100% 180px;
    background-repeat: no-repeat;
    &:before {
      content:'';
      width: 44px;
      height: 44px;
      border-radius: 22px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      background-color: #ffffff;
      float: right;
      right: 20px;
      position: relative;
    }
  }
  .slick-prev {
    left: 0;
    background-position: 0 180px;
    background-repeat: no-repeat;

    &:before {
      content:'';
      width: 44px;
      height: 44px;
      border-radius: 22px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      background-color: #ffffff;
      float: left;
      left: 20px;
      position: relative;
    }
  }

  .slick-slider .slick-list {
    opacity: 0.7;
  }

  .slick-track {
    background: #23528b;
  }
}