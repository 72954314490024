

 @toolbar-height: 78px;

/////////////////////BASE MODAL OVERLAY -- LAYOUT  ////////////////////


.overlay {
    .rgba(0,0,0,.95);
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;

    height: 100%;
    top: 0;
    & button#paraclose{
      z-index: 100000;
      position: absolute;
      right: 20px;
      top: 10px;
      cursor: pointer;
      padding: 0;
    }
  .modal-content {
    height: 100%;
    background-color: transparent;
    border: 0;
    border-radius: none;
    position: relative;
    display: block;
  }

  .media-dialog{
      
    width: 100%;
    height: 100%;
    position: fixed;
    & h3{
      font-weight: 300;
      font-size: 22px;
      line-height: 30px;
      margin: 25px 0;
      color: #333;
    }

    .column {
      .col(1);
      width:30px;
      position: absolute;
      right: 20px;
      vertical-align: top;
      display: table-cell;
      height: 100%;
      padding: 0;
      .transition(background-color, 1s);
      .transition(width, 1s);
      z-index: 10000;
      font-size:0;
      overflow: hidden;
      &[data-toggle-state=visible]{
        .col(3);
        right: -20px;
        padding: 0 15px;
        overflow-y: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -ms-overflow-style: auto;
        z-index: 1;
        background-color: #f1f1f1;
        float: right;
        & ~ .toolbar-wrapper {
          .row(9);
          position: absolute;
          margin-left: 0;
        }
        & ~ .mediaContainer{
          .row(9);
          padding-top: @toolbar-height + @double-padding;
          padding-left: @double-padding;
          padding-right: @double-padding;
          padding-bottom: @double-padding + @double-padding ;
          .transition(width, 0s);
          display: table-cell;
          vertical-align: middle;
          & .preview{
            position: relative;
            width: 100%;
            background-color: transparent;
            display:inline-block;
            height:100%;

            & .preview-wrapper{
              display: block;
              width: 100%;
              text-align: center;
              background-color: transparent;
              &:before {
                display:inline-block;
                content: '';
                height:100%;
                width:0;
                vertical-align:middle;
              }
              & img{
                max-width: 100%;
                display: inline-block;
                max-height: 100%;
                vertical-align: middle;
              }

            }
          }
        }
      }


    }
  }
}

.media-slider-container {
  padding-bottom: 150px!important;
}

.mediaContainer{
  .row(12);
  height: 100%;
  padding-top: @toolbar-height + @double-padding;
  padding-left: @double-padding;
  padding-right: @double-padding;
  padding-bottom: @double-padding + @double-padding ;
  .transition(width, 1s);
  display: table-cell;
  vertical-align: middle;

  & .preview{
        position: relative;
        width: 100%;
        background-color: transparent;
        height: 100%;

    & .preview-wrapper{
      display: block;
      width: 100%;
      text-align: center;
      background-color: transparent;
      height: 100%;

         &:before {
          display:inline-block;
          content: '';
          height:100%;
          width:0;
          vertical-align: middle;
        }
         & img{
          max-width: 100%;
          display: inline-block;
          max-height: 100%;
          vertical-align: middle;
      }
      & .video-js {
        display: inline-block;
        vertical-align: middle;
        &.vjs-fullscreen {
          display: block;
        }
      }
    }
    & canvas{

      overflow: scroll;
      display:inline-block;
      margin:0 auto;
      margin-bottom:3%;
      &:nth-last-of-type(1){
        margin-bottom:0;
      }
    }
  } 
}


//////////////////////// GESTION TYPE DOC ////////////////////////////////

  .type_document {
    width: 100%;
    height: 100%;
  }


.frame-container-cell.type_document {
  vertical-align: top;
  height: 100%;

  &.no-visualisation {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    

    .type_document {
      display: initial;
    }
  }

  .type_document {
    height: 90%;
    display: block;
    max-height: none;
    margin-top: 0;
  }
}  

  ///////////////////////////TOOLBAR AND BUTTONS //////////////////////////////////
  

  .toolbar-wrapper {
    .transition(width, 0.3s);
    width:100%;
    height: @toolbar-height;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 9999;
    position: absolute;
    text-align: center;
  }

.toolbar {
  min-height: @toolbar-height;
  line-height: @toolbar-height;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  display: inline-block;
  margin-left: 15px;
  & button {
    margin: 0;
    padding: 0;
    display:inline-block;
    height: @toolbar-height;
    width: @toolbar-height;
    border: none;
    float:left;
    background-color: #3c3c3c;
    border-radius: 0;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    &:first-child {
      border-left: 1px solid @font-ultralight-color;
      &:hover {
        border-right: 1px solid @font-ultralight-color;
        border-bottom: 1px solid @font-ultralight-color;
      }
    }
    &:hover {
      background: #333;
      color: @font-ultralight-color;
      border: 0;
      border-right: 1px solid @font-ultralight-color;
      border-bottom: 1px solid @font-ultralight-color;
      &:after {
        color: @font-ultralight-color;
      }
    }
    & p {display: none;}
    &:before, &:after{
      .font-size(24);
      color: @soft-border-color;
    }
  }
}
  // This wrapper is used to work around a bug in firefox where an element with
  // a 100% width inside an element with `table-cell` display is sized according
  // to his grand-parent instead of parent.

.no-visualisation {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  margin-top: -100px;

  h3 {
    font-weight: 300;
    .font(14);
  }
}


////////////////////////    PLAYER  VIDEO    //////////////////////////////////////////



.mejs-controls .mejs-time-rail .mejs-time-loaded {
  background: none;
  background-color: @brand-primary !important;
}

.mejs-mediaelement {
  .me-cannotplay {
    width: 100% !important;
    height: 100% !important;

    // Doesn't seem to be useful (mdarse 07/03/2014)
    display: block;
    margin: 0 auto;
  }
}

/**
 * Overwrite FlexPaper
 */
.modal-content {
  display: block !important;
}



////// SIDEBAR ///////////

.retracted-content {
    .hide();
    width: 60px;
    float: left;
    position: absolute;
    right: 0;
    z-index: 900;
}

  aside {
    overflow-y: hidden;
    -ms-overflow-style: hidden;
    .content{
      float: none;
      padding-bottom: 60px;
      padding: @double-padding;
      &:first-child p{
        margin-top: 0;
      }
      .scroll-area-wrapper {
        overflow: hidden;
        position: relative;
      }
      #info-overlay{
        .icon-infos();
        position: absolute;
        top: 60px;
        left: 0;
        margin: 0;
        z-index:;
        &:focus{
          color: @font-base-color;
        }
      }

    }
    .info {
      
      &.retractable {
        display: none;
        position: absolute;
        top: 63px;
        right: 3px;
        cursor: pointer;
      }

      .glyphicon {
        margin-right: 15px;
        font-size: 20px;
        vertical-align: text-bottom;
      }
    }

    h4 {
      font-weight: 700;
      .font(12);
      color: #b2b2b2;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    p {
      font-family: Arial, sans-serif;
      .font(13) ;
      margin-bottom: 15px;
      margin-top: 5px;
      color: @font-base-color;
    }
  }




// IMAGETTES SLIDER ///////
.slide-list {
  &[data-toggle-state=visible] {
    .overlay-slider {
      .transition(height, 1s);
      height: 90px;
    }
  }
  &[data-toggle-state=hidden] {
    .overlay-slider {
      height: 0;
    }
  }


  .overlay-slider{
    position: absolute;
    display: inline-block;
    bottom: 15px;
    left: 30px;
    height: 90px;
    max-width: 95%;
    display: inline-block;
    overflow: hidden;

    & .slider-wrapper{
      display: inline-block;
      width:30000%;
      padding: 5px;

    }

    & .active{
      .opacity(1);
      outline: 3px solid @brand-primary;
    }
  }


    .overlay-slide{
      margin-right: 10px;
      width: 80px;
      height: 80px;
      display: inline-block;
      .opacity(0.4);
      &:hover{
        .opacity(1);
      }
      & .title{display: none;}
      & .image {

        display: block;
        position: relative;
        width: 100%;
        background-color: transparent;
        display:inline-block;
        height:100%;

        & a{
          & .thumbnail-wrapper{
            display: block;
            width: 100%;
            height:100%;
            text-align: center;
            background-color: transparent;
            &:before {
              display:inline-block;
              content: '';
              height:100%;
              width:0;
              vertical-align:middle;
            }
            & img{
              max-width: 100%;
              display: inline-block;
              max-height: 100%;
              vertical-align: middle;
              width: auto;
              height: auto;
            }
          }


        }
        width: inherit;
        height: inherit;
        line-height: 80px;
        float: left;

        & a {
          height: inherit;
          line-height: inherit;
          & .thumbnail-wrapper{
            display: inline-block;
            text-align: center;
            background-color: #262626;
            &  .background-thumbnail{
              display: none;
            }
          }

        }
      }

    }


    .overlay-slide-buttons {
      display: inline-block;
      position: absolute;
      bottom: 30px;
      right: 30px;
      .container(130);
      height: 60px;
      z-index: 1;
      & button{
        margin: 0;
        padding: 0;
        display:inline-block;
        float: left;
        width: 60px;
        height: 60px;
        &:after{
          .font(70);
        }
      }
      & #paradeprev{
        .icon-rounded_left();
      }
      & #paradenext{
        .icon-rounded_right();
      }

    }



    /////////// OVERLAY SPINNER ///////


    .isloading-overlay{

      .bg-simple(@spinner)!important;

    }
  }



 .viewer-controls{
   position: absolute;
   top: 0;
   height: 70px;
   display: block;
   left: 60px;
   z-index: -10;
   color: #ffffff;
   z-index: 1000000;
   & .button-bar{
     display: inline-block;
     height: inherit;
   }
   #zoomIn{
     .icon-zoom_in();
   }
   #zoomOut{
     .icon-zoom_out();
   }
   & .pdf-pagination{
     & label {
       //display: inline-block;
       display: none;
       margin: 0 @simple-padding;
       .font(13);
       color: @font-base-color;
     }
     & input[type="number"]{
       width: 60px;
       display: inline-block;
     }
     & i {
       .font(15);
       color: @font-base-color;
       margin: 10px;
     }
     & #totalPages{
       .font(13);
       color: @font-base-color;
       margin: 10px;
     }
     & #previous{
       .icon-up_bold();
       float: right;
     }
     & #next{
       .icon-down_bold();
       float: right;
     }
   }
 }


  .viewer-controls {
   min-height: @toolbar-height;
   line-height: @toolbar-height;
    left: 0;
   & button {
     margin: 0;
     padding: 0;
     display:inline-block;
     height: @toolbar-height;
     width: @toolbar-height/1.5;
     border: none;
     & p {display: none;}
     &:before, &:after{
       .font-size(36);
       color: @soft-border-color;
     }
   }
 }



