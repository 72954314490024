aside.nav{
.col(3);
 & form {
   & .header{display: none;}
   & .reset{display: none;}
  }
 & .form-search {
   .row(9);
   float: right;
   padding-left: 35px;

   & .input-group{
     position: relative;
     & input {
       height: 45px;
       background-color: #fafafa;
       border: 1px solid #ebebeb;
       box-shadow: none;
       width: 100%;
       border: none;
       max-width: 100% !important;
       .font(16);
       color: #373334;
       background-color: #b3b3b3;
     }

     button, button:hover, button:active {
       width: 45px;

       .icon-search();
       &:after{
         color: #373334;
         .font-size(20);
       }
       position: absolute;
       top: 2px;
       right: 10px;
       height: 50px;
       background-color: transparent;
       border: 0;
       margin: 0;
       padding: 0
     }
   }
 }


}

.media-counter {
  line-height: 1;
  font-weight: 100;
  text-align: left;
  color: #555;
  margin-top: 0;
  display: block;
  padding: 20px 0;

  .number {
    line-height: 35px;
    font-size: 29px;
    display: block;
    font-weight: bold;
  }
  .unit {
    font-size: 21px;
    display: block;
  }
}

#filters{ 
  height: 100%;
  border-right: 1px solid @border-light;
 
  hr{display: none;}
}

#filters{
  & >.form-group{
       & label.control-label {
         font-size: 20px;
         font-weight: 300;
         color: #4a63ae;
         font-family: 'avalon-plain';
         display: none;
         &[for="filters_expires"]{
           display: block;
           margin-top: 20px;
         }
       }
  }
}

/////////////// SEARCH TYPE ////////////////

.search_type{
  & input[type="radio"] + label{
    .font(12)!important;
    &:hover{
      color: @brand-primary !important;
      cursor: pointer;

    }
  }

  & input[type="radio"]:checked + label {
    color: @brand-primary !important;
    .font(12)!important;
  }
}

//////////////// FACETS  ///////////////////
.facets-container{
        overflow: hidden;
          border-collapse: collapse;
    &::-webkit-scrollbar { width: 0 !important }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none
}
.facets {
  max-height: 450px;
  & h4{
    font-size: 18px;
    font-weight: 300;
    color: #4a63ae;
    text-transform: none;
    margin-bottom: 10px;
    letter-spacing: 0.8px;
  }
  & ul {
    width: 100%;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: hidden;
    border-collapse: collapse;
    &::-webkit-scrollbar { width: 0 !important }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &:hover{
      overflow-y: auto;
    }
  }
  & ul > li {
    min-height: 18px;
    list-style: none;
    padding-left: 5px;

    & input[type="checkbox"]{
      display: none;
    }
    & label {
      .font(14);
      line-height: 20px;
      line-height: 2rem;
      color: #141414;
      text-transform: none;
      margin-bottom: 0;
      letter-spacing: 0.6px;
      &:hover{
        color: @brand-primary;
        cursor: pointer;
        text-decoration: underline;
      }
      &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 2.5px;
        background: #141414;
        position: relative;
        left: -5px;
        top: -2px;
        margin-right: 5px;
      }

    }
  }
}


//////////////// FILTERS ///////////////////

.form-group{
  margin: 0;
  & .checkbox{
    & input[type='radio']{
      display: none;
    }
    & input[type='checkbox']{
      display: none;
    }
    & label{
      .font(13);
      font-weight: 300;
      color: @font-base-color;
      text-transform: none;
    }
    & label[for="filters_search_type_0"]{
      color: @brand-primary;
    }
  }
}
