@nav-height: 60px;
.main-nav{}

.logo-container{
  .col(6);
  height: 80px;
  line-height: 80px;
  display: inline-block;
  @media @mobile{
    .col(12);
    float: none;
    text-align: center;
    height: 80px;
    line-height: 80px;
  };
  .logo {
    vertical-align: middle;
    width: 35%;
    display: inline-block;
    & img {
      max-width: 165px;
      width: 100%;
      vertical-align: middle;
      padding: 15px;
      padding-right: 50px;
      border-right: 1px solid @brand-secondary;
    }
  }
  & p {
    vertical-align: middle;
    width: 50%;
    display: inline-block;
    .font-size(20);
    color: @brand-secondary;
    margin: 0;
    line-height: 25px;
    & em {
      .font-size(15);
      color: #555;
    }
  }
}
.home .search-container {
  .col(12);
  padding: 0;
  & button {
    position: relative;
    top: 3px;
  }
}
.search-container {
  .col(9);
  padding: 0;
  @media @mobile{
    height: 80px;
    line-height: 80px;
  };
  & .form-control {
    width: 100%;
    max-width: 100%;
    display: inline-block;
    background: #c6d3e1;
    color: @brand-secondary;
    margin: 0;
  }
  & .input-group-btn {
    position: absolute;
    right: 6px;
    top: -4px;
    width: 40px;

    & .btn {
      margin: 0;
      background: none;
      .icon-search();
      &:after{
        .font(22);
        color: @brand-secondary;
        float: left;
      }
    }
  }
}

.logo-sanofi {
  .col(2);
  min-height: 100px;
  line-height: 100px;
  text-align: center;
  @media @mobile{
    .col(6);
    height: 80px;
    line-height: 80px;
    top: -1rem;
  };
  & .logo {
    margin: auto;
    vertical-align: middle;
    & img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
}

.navbar{
  display: block;
  height: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  font-family:@default-font-family;
  color: @font-base-color;
  .font-size(13);
  vertical-align: top;
  text-align: center;
  @media @mobile{
      .row();
      height: auto;
    };
  & ul{
    display: inline-block;
    margin: 0;
    padding: 0;
    height: @header-height;
    line-height: @header-height;
    vertical-align: middle;
    width: 100%;
    max-width: 1400px;
    @media @mobile{
      .row();
      height: auto;
    };

    & .active{
      & a {
        color: @brand-second;
        border-bottom: 3px solid @brand-second;
        z-index: 1;
      }
    }

  & li {
    display: inline-block;
    color: @font-base-color;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    padding:0;
    font-weight: 300;
    height: inherit;
    line-height: inherit;
    float: left;
      @media @mobile{
        .row();
      };
    &.last {
    }
    &:hover{
      cursor: pointer;
    }

    &:first-child{
      @media @mobile{
        padding: 0;
        margin-left: 0;
      };
    }

    > a {
      position: relative;
      display: block;
      color: inherit;
      .font-size(13);
      font-weight: 300;
      line-height: inherit;
      padding: 0 70px;
      text-transform: uppercase;
      font-weight: bold;
      &:hover,
      &:focus {
        color: @brand-second;
        border-bottom: 3px solid @brand-second;
        text-decoration: none;
      }
    }
  }
}


  .filters-wrapper.collapse {
    display: block;
  }

  .navbar-collapse .navbar-nav.navbar-right {
    margin-right: -15px;
  }

}
