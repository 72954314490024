
// Bullet proof fontface declaration
// http://www.paulirish.com/2009/bulletproof-font-face-implementation-syntax/


@font-face {
  font-family: Avenir;
  src: url("../fonts/Avenir-Book.eot?");
  src:  local("☺"), url("../fonts/Avenir-Book.eot?") format('eot'), url("../fonts/Avenir-Book.woff2?") format("woff2"), url("../fonts/Avenir-Book.woff?") format("woff"), url("../fonts/Avenir-Book.ttf?") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: Avenir;
  src: url("../fonts/Avenir-BookOblique.eot?");
  src:  local("☺"), url("../fonts/Avenir-BookOblique.eot?") format('eot'), url("../fonts/Avenir-BookOblique.woff2?") format("woff2"), url("../fonts/Avenir-BookOblique.woff?") format("woff"), url("../fonts/Avenir-BookOblique.ttf?") format("truetype");
  font-weight: 300;
  font-style: italic; }


@font-face {
  font-family: Avenir;
  src: url("../fonts/Avenir-Medium.eot?");
  src:  local("☺"), url("../fonts/Avenir-Medium.eot?") format('eot'), url("../fonts/Avenir-Medium.woff2?") format("woff2"), url("../fonts/Avenir-Medium.woff?") format("woff"), url("../fonts/Avenir-Medium.ttf?") format("truetype");
  font-weight: 500;
  font-style: normal; }


@font-face {
  font-family: Avenir;
  src: url("../fonts/Avenir-Black.eot?");
  src:  local("☺"), url("../fonts/Avenir-Black.eot?") format('eot'), url("../fonts/Avenir-Black.woff2?") format("woff2"), url("../fonts/Avenir-Black.woff?") format("woff"), url("../fonts/Avenir-Black.ttf?") format("truetype");
  font-weight: 700;
  font-style: black; }
