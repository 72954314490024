
//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

// @font-family

@font-face {
  font-family: 'avalon-plain';
  src: url('/assets/fonts/avalon-plain.eot');
  src: local('☺'), url('/assets/fonts/avalon-plain.woff') format('woff'), url('/assets/fonts/avalon-plain.ttf') format('truetype'), url('/assets/fonts/avalon-plain.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@client-font-family: avalon-plain;
@default-font-family: avalon-plain, sans-serif;



@font-family-sans-serif:  @client-font-family;

@font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

@font-size-base:          13px;
@font-size-large:         20px;
@font-size-small:         11px;

@font-size-h1:            48px;
@font-size-h2:            floor((@font-size-base * 2.15)); // ~30px
@font-size-h3:            ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4:            ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
@line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family:    inherit;
@headings-font-weight:    100;
@headings-line-height:    1.1;
@headings-color:          inherit;