////////////////CGU FIX TEMPLATE 

body.CGU {
  & .container {
    .push(1);
    .pull(1);
    & header{
      background-color: transparent;
      .row(12);
      & .logo-container{.container(250);}
      & .navbar {
        display: none;
      }
      & .form-search{display: none;}
    }
    & .content-header {
      display: none;
    }
  }
}


.cgu-modal{display: inline-block;}


////////////////CGU CONTAINER

.cgu-container{
  & .cgu-content{
    .col(10);
    .push(1);
    .pull(1);
    margin-top: 50px;
    margin-bottom: 100px;
    & article{
      .font-size(14);
      line-height: 20px;
      font-weight: 300;
      color: #373334;
      margin-top: @double-padding;
    }
    & h1{
      .font(32);
    }
    & h2{
      .font(26);
    }
    & h3{
      .font(22);
    }
    & h4{
      .font(18);
    }
    & h5{
      .font(16);
    }

    & h1,h2,h3,h4,h5{
      margin: @simple-padding 0;
      text-transform: uppercase;
      font-weight: 700;
      color: #373334;
    }
    & a {
      text-decoration: underline;
    }
  }
}
////////////////ARTICLE

.CGU{
  & .container {
    & .content-header{display: none;}
    & header{
      .row(12);
      float: none;
      & .navbar{display: none;}
    }
    & .cgu-container{
      & .cgu-wrapper{
        max-height: 700px;
        overflow-y: scroll;
        // @media @large, @xlarge{
        // .row(12);

        // };
        .row(12);
        background-color: #e5e5e5;
        .border-radius();
        .drop-shadow();
      }
    }
  }
}


// ////////CGU + HELP MODAL

// .cgu-modal,
// .help-modal{
// width: 100%;
// height: 100%;
// padding: 0;
// margin: 0;
// background-color: #fff;
// display: table;

// }
