body.basket {
  & .container {
    
    & header{
      background-color: transparent;
      .row(12);
      & .logo-container{.container(250);}
      & .navbar {
        display: none;
      }
      & .form-search{display: none;}
    }

    & .empty-cart {
      .font(14);
      color: #555;
    }
  }
  & .glyphicon-shopping-cart{
    color: @brand-second !important;
  }

  // Column layout
  .actions-and-products {
    padding-bottom: 20px;
    .row(12);
    & > .actions {
      margin-top: 20px;
      .intro{display:none;}
      .col(3);
      padding-left: 0;
      @media @tablet, @mobile{
        .col(2);
        padding: 0;
      };
      & .btn {
        max-width: 300px;
        font-size: 13px;
        text-align: right;
        color: @font-ultralight-color;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
        background-color: #555555;
        margin: 5px 0;
        height: 50px;
        padding: 5px 16px 14px;
        font-weight: normal;
        font-family: 'avalon-plain' !important;
        line-height: 40px;
        border: 0;
        .transition(background-color, 0.5s );
        @media @tablet, @mobile{
          font-size: 0;
          display: block;
          width: 95%;
          padding: 5px 10px 14px;
        };
        &.rename {
          padding: 5px 8px 14px;
        }
        & span {
          float: left;
          line-height: 40px;
          @media @tablet, @mobile {
            text-align: center;
            line-height: 40px;
            display: block;
            float: none;
          };
        }

        &:hover {
          color: #666;
          background-color: #dadada;
          border-color: #c3c3c3;
        }
      }
    }
    & > .products {
      .col(9);
      margin-bottom: 50px;
      padding-left: 0;
      @media @tablet, @mobile{
        .col(10);
        padding: 0;
      };
    }
  }
}



.cart-header{
  height: 85px;
  line-height: 85px;
  .row(12);
  float: left;
  & h1{
    float: left;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    .font(22);
    text-transform: uppercase;
    color: #ffffff;
    margin: 0;
    line-height: 85px;
    padding-right: @double-padding;
  }
  & .list-switch{
    display: inline-block;
    line-height: 85px;
    vertical-align: middle;
  }
}


.bar-buttons{
    display: inline-block;
    vertical-align: middle;
  & form{
    display: inline-block;
    float: left;
  }

}


.cart-breadcrumb{
  margin-top: 30px;
  .row(12);
  // margin-left: @double-padding;
  padding: 0 @double-padding;
  height: 40px;
  background-color: @brand-secondary;
  margin-bottom: 0;

  & a{
    display: inline-block;
    color: @font-ultralight-color;
    .font-size(12);
    text-transform: uppercase;
    margin: 0;
    padding:0;
    line-height: 40px;
    float: left;
  }
  & p{
    display: inline-block;
    color: #ffffff;
    .font-size(12);
    text-transform: uppercase;
    margin: 0;
    padding:0;
    line-height: 40px;
    float: left;

  }
  & .breadcrumb-separator{
    display: inline-block;
    color: #ffffff;
    margin: 0;
    padding:0;
    .icon-right_thin();
    line-height: 40px;
    float: left;
    &:after{
      display: inline-block;
    }
  }
  & .cart-counter{
    display: inline-block;
    color: @font-ultralight-color;
    .font-size(17);
    margin: 0;
    padding:0;
    line-height: 43px;
    float: right;
    margin-right: 0.3rem;
  }
  & .unit{
    display: inline-block;
    color: @font-ultralight-color;
    .font-size(17);
    margin: 0;
    padding:0;
    line-height: 43px;
    float: right;
  }
}