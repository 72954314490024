@import "variables.holly-glyph.less";

@font-face {
	font-family: hollyglyph;
	src:url('../fonts/holly-glyph.eot');
	src:url('../fonts/holly-glyph.eot') format('embedded-opentype'),
		url('../fonts/holly-glyph.ttf') format('truetype'),
		url('../fonts/holly-glyph.woff') format('woff'),
		url('../fonts/holly-glyph.svg') format('svg');
	font-weight: 100;
	font-style: normal;
	color: red;
}

.holly-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
	font-family: hollyglyph !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 0;
	text-decoration: none;
	.font-size(24);
	&:hover, &:active, &:focus{
		color: @brand-secondary;
		text-decoration: none;
	}
	// /* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-list_burger, [data-icon="list_burger"] {
	.holly-icon();
	&:after {
		content: @holly-list_burger;
		text-decoration: none;
	}
}
.icon-more_rounded {
	.holly-icon();
	&:after {
		content: @holly-more_rounded;
		text-decoration: none;
	}
}
.icon-more {
	.holly-icon();
	&:after {
		content: @holly-more;
		text-decoration: none;
	}
}
.icon-folder_add {
	.holly-icon();
	&:after {
		content: @holly-folder_add;
		text-decoration: none;
	}
}
.icon-grid {
	.holly-icon();
	&:after {
		content: @holly-grid;
		text-decoration: none;
	}
}
.icon-search {
	.holly-icon();
	&:after {
		content: @holly-search;
		text-decoration: none;
	}
}
.icon-zoom_in {
	.holly-icon();
	&:after {
		content: @holly-zoom_in;
		text-decoration: none;
	}
}
.icon-zoom_out {
	.holly-icon();
	&:after {
		content: @holly-zoom_out;
		text-decoration: none;
	}
}
.icon-cart {
	.holly-icon();
	&:after {
		content: @holly-cart;
		text-decoration: none;
	}
}
.icon-command {
	.holly-icon();
	&:after {
		content: @holly-command;
		text-decoration: none;
	}
}
.icon-download {
	.holly-icon();
	&:after {
		content: @holly-download;
		text-decoration: none;
	}
}
.icon-folder {
	.holly-icon();
	&:after {
		content: @holly-folder;
		text-decoration: none;
	}
}
.icon-share {
	.holly-icon();
	&:after {
		content: @holly-share;
		text-decoration: none;
	}
}
.icon-upload {
	.holly-icon();
	&:after {
		content: @holly-upload;
		text-decoration: none;
	}
}
.icon-avatar {
	.holly-icon();
	&:after {
		content: @holly-avatar;
		text-decoration: none;
	}
}
.icon-fullscreen {
	.holly-icon();
	&:after {
		content: @holly-fullscreen;
		text-decoration: none;
	}
}
.icon-map {
	.holly-icon();
	&:after {
		content: @holly-map;
		text-decoration: none;
	}
}
.icon-ring {
	.holly-icon();
	&:after {
		content: @holly-ring;
		text-decoration: none;
	}
}
.icon-rule {
	.holly-icon();
	&:after {
		content: @holly-rule;
		text-decoration: none;
	}
}
.icon-save {
	.holly-icon();
	&:after {
		content: @holly-save;
		text-decoration: none;
	}
}
.icon-size {
	.holly-icon();
	&:after {
		content: @holly-size;
		text-decoration: none;
	}
}
.icon-double_bold_left {
	.holly-icon();
	&:after {
		content: @holly-double_bold_left;
		text-decoration: none;
	}
}
.icon-double_bold_right {
	.holly-icon();
	&:after {
		content: @holly-double_bold_right;
		text-decoration: none;
	}
}
.icon-double_thin_left {
	.holly-icon();
	&:after {
		content: @holly-double_thin_left;
		text-decoration: none;
	}
}
.icon-double_thin_right {
	.holly-icon();
	&:after {
		content: @holly-double_thin_right;
		text-decoration: none;
	}
}
.icon-mail {
	.holly-icon();
	&:after {
		content: @holly-mail;
		text-decoration: none;
	}
}
.icon-rss {
	.holly-icon();
	&:after {
		content: @holly-rss;
		text-decoration: none;
	}
}
.icon-link {
	.holly-icon();
	&:after {
		content: @holly-link;
		text-decoration: none;
	}
}
.icon-lock {
	.holly-icon();
	&:after {
		content: @holly-lock;
		text-decoration: none;
	}
}
.icon-new_window {
	.holly-icon();
	&:after {
		content: @holly-new_window;
		text-decoration: none;
	}
}
.icon-send {
	.holly-icon();
	&:after {
		content: @holly-send;
		text-decoration: none;
	}
}
.icon-view {
	.holly-icon();
	&:after {
		content: @holly-view;
		text-decoration: none;
	}
}
.icon-warning {
	.holly-icon();
	&:after {
		content: @holly-warning;
		text-decoration: none;
	}
}
.icon-check {
	.holly-icon();
	&:after {
		content: @holly-check;
		text-decoration: none;
	}
}
.icon-checkbox_off {
	.holly-icon();
	&:before {
		content: @holly-checkbox_off;
		text-decoration: none;
	}
}
.icon-checkbox_on {
	.holly-icon();
	&:before {
		content: @holly-checkbox_on;
		text-decoration: none;
	}
}
.icon-options {
	.holly-icon();
	&:after {
		content: @holly-options;
		text-decoration: none;
	}
}
.icon-radio_off {
	.holly-icon();
	&:after {
		content: @holly-radio_off;
		text-decoration: none;
	}
}
.icon-radio_on {
	.holly-icon();
	&:after {
		content: @holly-radio_on;
		text-decoration: none;
	}
}
.icon-comment {
	.holly-icon();
	&:after {
		content: @holly-comment;
		text-decoration: none;
	}
}
.icon-down_bold {
	.holly-icon();
	&:after {
		content: @holly-down_bold;
		text-decoration: none;
	}
}
.icon-etc {
	.holly-icon();
	&:after {
		content: @holly-etc;
		text-decoration: none;
	}
}
.icon-left_bold {
	.holly-icon();
	&:after {
		content: @holly-left_bold;
		text-decoration: none;
	}
}
.icon-right_bold {
	.holly-icon();
	&:after {
		content: @holly-right_bold;
		text-decoration: none;
	}
}
.icon-up_bold {
	.holly-icon();
	&:after {
		content: @holly-up_bold;
		text-decoration: none;
	}
}
.icon-close_bold {
	.holly-icon();
	&:after {
		content: @holly-close_bold;
		text-decoration: none;
	}
}
.icon-close_thin {
	.holly-icon();
	&:after {
		content: @holly-close_thin;
		text-decoration: none;
	}
}
.icon-delete {
	.holly-icon();
	&:after {
		content: @holly-delete;
		text-decoration: none;
	}
}
.icon-down_thin {
	.holly-icon();
	&:after {
		content: @holly-down_thin;
	}
}
.icon-left_thin {
	.holly-icon();
	&:after {
		content: @holly-left_thin;
	}
}
.icon-right_thin {
	.holly-icon();
	&:after {
		content: @holly-right_thin;
	}
}
.icon-up_thin {
	.holly-icon();
	&:after {
		content: @holly-up_thin;
	}
}
.icon-calendar {
	.holly-icon();
	&:after {
		content: @holly-calendar;
	}
}
.icon-rounded_left {
	.holly-icon();
	&:after {
		content: @holly-rounded_left;
	}
}
.icon-rounded_right {
	.holly-icon();
	&:after {
		content: @holly-rounded_right;
	}
}
.icon-taquet_both {
	.holly-icon();
	&:after {
		content: @holly-taquet_both;
	}
}
.icon-taquet_down {
	.holly-icon();
	&:after {
		content: @holly-taquet_down;
	}
}
.icon-taquet_up {
	.holly-icon();
	&:after {
		content: @holly-taquet_up;
	}
}
.icon-time {
	.holly-icon();
	&:after {
		content: @holly-time;
	}
}
.icon-history {
	.holly-icon();
	&:after {
		content: @holly-history;
	}
}
.icon-infos {
	.holly-icon();
	&:after {
		content: @holly-infos;
	}
}
.icon-pause {
	.holly-icon();
	&:after {
		content: @holly-pause;
	}
}
.icon-play {
	.holly-icon();
	&:after {
		content: @holly-play;
	}
}
.icon-sound {
	.holly-icon();
	&:after {
		content: @holly-sound;
	}
}
.icon-stats {
	.holly-icon();
	&:after {
		content: @holly-stats;
	}
}
.icon-edit {
	.holly-icon();
	&:after {
		content: @holly-edit;
		font-size: 3.2rem;
	}
}
.icon-embed {
	.holly-icon();
	&:after {
		content: @holly-embed;
	}
}
.icon-settings {
	.holly-icon();
	&:after {
		content: @holly-settings;
	}
}
.icon-trash {
	.holly-icon();
	&:after {
		content: @holly-trash;
	}
}

