// .btn-parade-light {
//   .button-variant(@btn-parade-light-color; @btn-parade-light-bg; @btn-parade-light-border);
// }

.gold-link {
  color: @brand-primary;
}


.btn-icon {
	display: block;
 	width: 40px;
 	height: 40px;
  	padding: 8px 0 8px 0;
  	margin: 0;
  	background-color: transparent;
  	border: none;
  	display: inline-block;
  	&:after{
	  	margin: 0;
	    padding: 0;
	    display: inline-block;
	    margin-left: -10px;
  	}
  	&:hover{
  		&:after{
  			color: @brand-primary;
  		}

  	}
  	&:hover{
  		&:after{
  			outline-color: @brand-primary;
  		}

  	}
}
// Button Reset

button{
	border: 0;
	box-sizing: border-box;
}

.btn-validation{
	background-color:@success-color!important;
	border: solid 1px darken(@success-color, 3%)!important;
	color: #ffffff;
	&:hover{
	background-color: darken(@success-color, 10%)!important;
	border: solid 1px darken(@success-color, 15%)!important;
	}
}

.btn-negative{
	background-color:@alert-color!important;
	border: solid 1px darken(@alert-color, 3%)!important;
		&:hover{
		background-color: darken(@alert-color, 10%)!important;
		border: solid 1px darken(@alert-color, 15%)!important;
		}
}
button[type="submit"]{
margin: @double-padding 0;
}

.item-btn{	
	background: transparent;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	height: 20px;
	display: block;
	padding: @simple-padding;
	border: 2px solid #ffffff;
	text-align: center;
	vertical-align: middle;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	text-overflow: ellipsis;
	float: none;

	& p{
	    text-transform: uppercase;
	    .font(11);
	    text-overflow: ellipsis;
	    text-align: center;
	    margin: -5px 0;
	    font-weight: 700;
	    color: #ffffff;
	    font-family: @font-family-base !important;
    	overflow: hidden;
    }
}

.btn{
	max-width: 400px;
	width:100%;
    height: 40px;
    line-height: 40px;
    .font-size(13);
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    line-height: 40px;
    background-color: @brand-secondary;
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    padding: 0px @simple-padding;
    .border-radius();
    //border: solid 1px darken(@brand-second, 3%);
    .transition(background, 300ms);
    .transition(border, 300ms);
    margin: @simple-padding 0;
    @media @mobile{
    	max-width: 100%;
   	};
    & a{
    	width: 100%;
    	text-align: center;
    	height: 40px;
    	line-height: 35px;
    	margin: 0;
    	padding: 0;
    	border: 0;
    	color: #ffffff;
    	display: inline-block;
    	vertical-align: middle;
    }
   &-cancel{
	background-color:@cancel-color!important;
	border: solid 1px darken(@cancel-color, 3%)!important;
	color: @font-dark-color !important;
	font-weight: 300;
	max-width: 100px !important;
		&:hover{
		background-color: darken(@cancel-color, 10%)!important;
		border: solid 1px darken(@cancel-color, 15%)!important;
		color: @font-dark-color !important;
		}
	}
}
.btn:hover{
 	background-color: darken(@brand-secondary, 8%);
	color:#ffffff;
	border: solid 1px darken(@brand-secondary, 13%);
}
.btn:focus{
	outline-color: lighten(@brand-secondary, 10%);
	outline-width: 5px;
}

.item-btn{
	&:hover {
		color: @brand-primary;
		text-decoration: none;
	}
}


a {
text-decoration: none;
	&:hover {
	color: @brand-primary;
	text-decoration: none;
	}
	&:visited{
	text-decoration: none;
	}
}

button.close{
.icon-close_thin();

}


.btn-icon{
	margin: auto;
	display: block;
	width: ~"@{icon-small}px";
	height: ~"@{icon-small}px";
	color: @default-btn-icon-color;
	background: transparent;
	border:none;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	height: 20px;
	display: block;
	float: left;
	padding: @simple-padding;
		
	&:hover{
		background: transparent;
		& .item-btn :before{
		color: @brand-primary;	
		}
	}
	&:focus{
		outline: none;
	}

	&:before, &:after{
		.font-size(24);
	}
}


//////////////BAr buttons ////////////


form.validation{
	.row(12);
	& .btn{
		float: right;
		display: inline-block;
		&:first-child{
			margin-left: 5px;
		}		
	}
}


///////// PICTO BUTTONS ///////

button#paraclose{
	.icon-close_thin();
}

button#full-screen{
.icon-fullscreen();
}

button#paradenext{

.icon-right_thin();
}

button#paradeprev{
.icon-left_thin();
}

button#paraclose{
.icon-close_thin();
}

button#info-overlay{
.icon-infos();

}

button.action-cart-create{
.icon-folder_add ();
	&:after {
		content: initial;
	}
}

button.download, button[data-action="download"]{
.icon-download();
}
button.share{
.icon-share();
}
button.empty,button.remove, button.cart-delete, button.delete {
.icon-trash();
}

.bar-buttons{
display: inline-block;
}

button.add-cart,button[data-action="cart-add"] {
.icon-cart();
}


