body.auth {
  background: @body-default-bg;
  background-size:cover;
  -ms-behavior: url('/assets/polyfill/backgroundsize.min.htc');
  -webkit-background-size:cover;
  &:after{
    content:'';
    width:100%;
    height: 100%;
    opacity: 0.7;
    background-image: linear-gradient(to bottom, #003871, #003871);
  }
  .login-logo {
    text-align: center;
  }

  .lang-switch{
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      background-color:white;
      padding: 10px 25px;

    & li{
      list-style: none;
      display: block;
      float: left;
      margin: 0;
      text-transform: uppercase;
      font-size: 13px;
      
      &:first-child {
      &:after {
      content: "|";
      padding: 0 10px;
      }
    }
      a{
      color:#c2c2c2;
      }
     
      a:hover{ 
     
      color: @brand-second;
      text-decoration: none;
      font-weight: bold;
      }
    }
    
    .active{
      
      & a {
        color:@brand-primary;
        font-weight: bold;
      }
    }
  }
  .auth-container {
    max-width: 390px;
    padding: 15px 30px;
    width: 100%;
    background-color: white;
    margin: 125px auto 30px auto;  
    clear:both;
    display: block;
    .font-size(12);
    & button[type="submit"] {
      margin: @simple-padding 0;
    }
    & .form-group {
      margin-bottom: 0;
    }
    & .btn {
      font-weight: normal;
      &.oauth {
        background: @brand-second;
      }
    }
  }

  & .white-logo {
    position: absolute;
    left: 60px;
    top: 40px;
    width: 155px;
  }

  .login-logo {
    margin: auto;
    text-align: center;
    
    img {
      max-width: 100%;
      margin-bottom: 10px;
    }

    p {
      color: #8c8c8c;
      margin-bottom: 20px;
    }
  }
    

  h2 {
    text-align: center;
    text-transform: uppercase;
    padding: 10px 0;
    margin: 0;
    font-size: 22px;
    font-weight: 100;
    color: @brand-primary;
    display: block;
  }
    
.forms-container{display: block;}

  hr {
    border-top: 1px solid #23528b;
  }
  .login-intro {
    color: #555555;
    h4 {
      display: block;
      float: none;
      color: #003974;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
      font-family: 'avalon-plain';
    }
  }
  .form-group {
    label {
      display: block;
      float: none;
      color: #003974;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
      font-family: 'avalon-plain';
    }
  
    input {
      float: none;
      background: #fff;
      margin:0;
    }
  }
// Pass recover link in login template
  .pass-recover { 
    height: auto;
    margin: 15px 0 15px 0;
    clear: both;
    
    a {
      color: #737373;
      font-size: 12px;
      display: block;
      text-decoration: underline;
    }
    
    a:hover{color: @brand-primary;}

  } 


// Registration link in login template
  
  .registration-access {
    margin: 0 0 30px 0;
    // padding-top: 30px;
    // padding-bottom: 30px;
    // border-top: 1px solid #e6e6e6;
    // border-bottom: 1px solid #e6e6e6;
    box-sizing: content-box;
    overflow:auto;
    &:after{ 
    clear: both;
    content: "";
    display: table;}

    h4 {
      display: none;
      font-size: 13px;
      color: #737373;
      font-weight: 400;
      float: left;
    } 
    a{
      width: 100%;
      // float: right;

    }
  }

  .sanofi-logo {
    display: block;
    margin-bottom: 15px;
    margin-top: 30px;
    clear: both;
    & img{
      margin: 0 auto;
      display: block;
    }
  }

  footer{
    position: absolute;
    bottom: 0;
  }
}


.form-horizontal::after{    
    clear: both;
    content: "";
    display: table;
  }









