
body.account{
  & .container{
    float: none;
    margin: auto;
    & .account-container{
      margin-top: 100px;
      .border-radius(5);
      .drop-shadow();  
      .col(8);
      .push(2);
      padding: 0 @double-padding + @double-padding @double-padding;
      background-color: #ffffff;
      margin-bottom: 90px;
      & .separator{
      // border-top: 1px solid @separator-color;
      }
      & input, select {
        max-width: inherit;
      }
    }
    & h1{
      .font(52);
      font-family:@font-family-base;
      color: @font-dark-color;
      padding: @double-padding 0;
      text-transform: uppercase;
      font-weight: 700;
    }
    & h2{
      font-family:@font-family-base;
      color: @font-base-color;
    }
    & .content-header{display: none;}
    & header{
      & .form-search{display: none;}
      // & .navbar{display: none;}
    }
  }
}

