

//  Inspired by the article by MICHAŁ OCHMAN
// http://blog.scur.pl/2012/06/variable-media-queries-less-css/

//////// EXEMPLES ////////

  // @media @mobile, @tablet{background-color:green;};
  
  // .mq-max(800px,{
  //   background-color:green;
  //   });
  //  .mq-min(1000px,{
  //   background-color:red;
  //   });
  //  .mq(320px,650px,{
  //   background-color:yellow;
  //   });



// ############### Breakpoints ############### //


@mobile:  ~'only screen and (max-width:@{bkpoint-mobile})';
@tablet:  ~'only screen and (min-width:@{bkpoint-tablet-min}) and (max-width:@{bkpoint-tablet-max})';
@desktop: ~'only screen and (min-width:@{bkpoint-desktop-min}) and (max-width:@{bkpoint-desktop-max})';
@large:   ~'only screen and (min-width:@{bkpoint-large-min}) and (max-width:@{bkpoint-large-max})';
@xlarge:  ~'only screen and (min-width:@{bkpoint-xlarge-min})';




@highdensity: ~"only screen and (-webkit-min-device-pixel-ratio: 1.5)",
              ~"only screen and (min--moz-device-pixel-ratio: 1.5)",
              ~"only screen and (-o-min-device-pixel-ratio: 3/2)",
              ~"only screen and (min-device-pixel-ratio: 1.5)";

// @bkpoint-mobile: 768px;
// @bkpoint-tablet-min:@bkpoint-mobile;
// @bkpoint-tablet-max: 959px;
// @bkpoint-desktop-min:960px;
// @bkpoint-desktop-max:1200px;
// @bkpoint-large-min:1201px;
// @bkpoint-large-max:1399px;
// @bkpoint-xlarge-min:1400px;



// ############### Editable breakpoint ############### //

.mq-max (@maxWidth; @fn) {
    @media only screen and (max-width: @maxWidth) {
        @fn(); 
    }
}

.mq-min (@minWidth; @fn) {
    @media only screen and (min-width: @minWidth) {
        @fn();
    }
}


.mq(@minWidth; @maxWidth; @fn) {
    @media only screen and (min-width: @minWidth) and (max-width: @maxWidth) {
        @fn();
    }
}

// ############### Utilities ############### //

.visible(@bkpoint) when (default()){
    @media @bkpoint {
          display: block;
    }
}

.visible(@bkpoint) when (ispixel(@bkpoint)){
    @media only screen and (min-width: @bkpoint) {
          display: block;
    }
}

.invisible(@bkpoint){
    @media @bkpoint {
          display: none;
    }
}

.invisible(@bkpoint) when (ispixel(@bkpoint)){
    @media only screen and (max-width: @bkpoint) {
          display: block;
    }
}

.hide(){
  display: none;
}
.show(@display:block){
  display: @display;
}



