.thumbnail-wrapper{
  width: 100%;
  height:inherit;
  line-height:inherit;
  overflow: hidden;
  background-color: @thumbnail-background-color;
  max-height: inherit;
  display: block;
  position: relative;
  .border-radius();

  &  .background-thumbnail{
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: inherit;
    line-height: inherit;
    @bg-thumbnail-transparency();
    background-size: contain;
    z-index: 0;
    .rgba(66, 62, 63,0.05);
    background-position: center center;
    max-height: inherit;

  }

  & .file-icon-small{
    position: absolute;
    bottom: 15px;
    right: 15px;
  }

  & img {
    @media @mobile, @tablet, @desktop, @large, @xlarge{
      position: absolute;
      left: 50%;
      top: 50%;
      height: 100%;
      width: auto;
      -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
    }
  }
}


/*.landscape{
     & img{
       width: auto;
       height: 100%;
  }
}
.portrait{
  & img{
    height:inherit;
  }
}*/
.square{
  & img{
    min-height: 100%;
    @media @mobile, @tablet{
      width:100%;
    }
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    @media @mobile, @tablet{
      width: 100%;
      height: initial;
    };
  }
}

.thumbnail {
    position: relative;
    overflow: hidden;

    // Vertical + horizontal image centering
    display: table;
    width: 100%;
    .thumbnail-wrapper {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    &.cropped {
      display: block;
      .thumbnail-wrapper {
        margin: 0 auto; display: block; overflow:hidden;
      }
    }

    img {
        // .img-responsive(inline-block);
        max-height: 100%;
    }

    &.cropped img {
        height:auto!important;width:auto!important;max-width:none!important;max-height:none!important;
    }

    &.icon-only .file-icon {
        margin: auto;
        position: static;
    }
    .file-icon {
        display: block;
        position: absolute;
        right: 4px;
        bottom: 4px;
    }
}

.file-icon {
    .file-icon-position(14); // Default icon
}

.file-icon-jpg,
.file-icon-jpeg,
.file-icon-png,
.file-icon-gif,
.file-icon-tiff {
    .file-icon-position(0);
}
.file-icon-m4v,
.file-icon-mp4,
.file-icon-mpg,
.file-icon-mpeg,
.file-icon-ts,
.file-icon-mov,
.file-icon-wmv,
.file-icon-avi {
    .file-icon-position(1);
}
.file-icon-pdf {
    .file-icon-position(2);
}
.file-icon-mp3,
.file-icon-m4a,
.file-icon-ra,
.file-icon-wma
 {
    .file-icon-position(3);
}
.file-icon-zip {
    .file-icon-position(4);
}
.file-icon-indd,
.file-icon-indl,
.file-icon-indt,
.file-icon-indb {
    .file-icon-position(5);
}
.file-icon-ai {
    .file-icon-position(6);
}
.file-icon-fla {
    .file-icon-position(7);
}
.file-icon-psd {
    .file-icon-position(8);
}
.file-icon-eps {
    .file-icon-position(9);
}
.file-icon-doc,
.file-icon-docx {
    .file-icon-position(10);
}
.file-icon-ppt,
.file-icon-pptx {
    .file-icon-position(11);
}
.file-icon-xls,
.file-icon-xlsx {
    .file-icon-position(12);
}
.file-icon-xls,
.file-icon-xlsx {
    .file-icon-position(14);
}

.file-icon-xsmall {
    @size: 16px;
    .sprite-retina('../img/icons/icons-16.png', '../img/icons/icons-32.png', @size, @size, @size, auto);
    width: @size;
    height: @size;
}
.file-icon-small {
    @size: 32px;
    .sprite-retina('../img/icons/icons-32.png', '../img/icons/icons-64.png', @size, @size, @size, auto);
    width: @size;
    height: @size;
    position: absolute;
    right: 15px;
    bottom: -15px;
}
.file-icon-medium {
    @size: 64px;
    .sprite-retina('../img/icons/icons-64.png', '../img/icons/icons-128.png', @size, @size, @size, auto);
    width: @size;
    height: @size;
}
.file-icon-large {
    @size: 128px;
    .sprite-retina('../img/icons/icons-128.png', '../img/icons/icons-256.png', @size, @size, @size, auto);
    width: @size;
    height: @size;
}
.file-icon-xlarge {
    @size: 256px;
    .sprite-retina('../img/icons/icons-256.png', '../img/icons/icons-256.png', @size, @size, @size, auto);
    width: @size;
    height: @size;
}

.file-icon-position(@index) {
    @index-calc: @index * (100/14);    
    background-position: 0 ~'@{index-calc}%'!important;
}

