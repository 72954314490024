
//// This must be remove from this styleshit

@header-height: 60px;
@header-right-height: @header-height;
@nav-height: @header-height;
@content-header-height: 300px;
@blade-element-color: @font-base-color;
@header-padding-vertical: (((@header-height - @line-height-computed) / 2) - 1);

.img-circle {
  border-radius: 50%; // set radius in percents
}
.size(@height: 5px, @width: 5px) {
  width: @width;
  height: @height;
}
.square(@size: 5px) {
  .size(@size, @size);
}
////////////////////////////  ####### CONTENT HEADER - SECTIONS TITLES ########    ////////////////////////////  

.content-header {
  .col(12);
  line-height: 110px;
  padding: 0;
  h1 {
    .col(3);
    padding: 0;
    padding-left: 15px;
    font-size: 24px;
    margin: 0;
    color: #555;
    text-transform: uppercase;
    font-weight: 500;
  }
  h2 {
    font-size: 15px;
    color: #4a63ae;
    display: block;
    float: left;
    padding: 30px 0;
    font-weight: bold;
    text-transform: uppercase;
  }
}




////////////////////////////  ####### HEADER ########    ////////////////////////////  
.header-bar {
  .clearfix();
  color: @header-color;
  max-width: 1400px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  position: relative;
  a {
    color: #333;

    &:hover,
    &:focus {
      color: @header-link-hover-color;
    }
  }

  .logo {

    margin: 0;
    display: none;
  }

  .header-bar-right {
    float: right;
    margin-right: 70px;
    .cart,
    .account{
      float: left;
    }
    .lang-switch{
      position: absolute;
      display: block;
      top: 0;
      right: 10px;
      padding: 13px 0 25px;
      & li{
        list-style: none;
        display: block;
        float: left;
        margin: 0;
        text-transform: uppercase;
        font-size: 13px;

        &:first-child {
          &:after {
            content: "|";
            padding: 0 10px;
            color: @font-darker-color;
          }
        }
        a{
          color:#333;
        }

        a:hover{

          color: @brand-second;
          text-decoration: none;
          font-weight: bold;
        }
      }

      .active{

        & a {
          color:@brand-primary;
          font-weight: bold;
        }
      }
    }
  }
  .cart {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    position: relative;
    font-size: 18px;
    text-align: center;
    margin-right: 20px;

    & p{
      margin-right: 10px;
      float: left;
      font-size: 13px;
      color: #333;
      line-height: 24px;
    }
    & .glyphicon-shopping-cart{
      float: right;
      padding-top: 9px;
      color: #333;
      position: relative;
      top: 15px;
      .icon-cart();
      &:hover {
        color: @brand-second;
      }
    }
    &:hover {
      color: @brand-second;
      & p {color: @brand-second;}
    }
    .cart-item-counter {
      display: block;
      width: 22px;
      height: 22px;
      line-height: 18px;
      position: absolute;
      top: 6px;
      right: -18px;
      font-size: 10px;
      font-weight: 500;
      border: 2px solid #ffffff;
      .img-circle();
      color: @cart-counter-color;
      background-color: @brand-secondary;
      &.empty {
        display: none;
      }
    }
  }
  .account/*.dropdown*/   {

    &.open,
    &:hover {

    }
    a.dropdown-toggle {
      display: inline-block;
      padding: @header-padding-vertical 12px;
      padding-right: 100px;
      padding: 0 15px 0 7px;
      & > span {
        padding: 10px 0;
        display: inline-block;
      }
      .caret {
        margin-left: 5px;
      }
      &:hover,
      &:focus {
        background-color: transparent;
        text-decoration: none;
      }
    }
    .dropdown-menu {
      right: 0;
      left: auto;

      // Prevent header override
      > li > a {
        color: @dropdown-link-color;
        &:hover,
        &:focus {
          color: @dropdown-link-hover-color;
          background-color: @dropdown-link-hover-bg;
        }
      }
    }
    .picture {
      .square(@header-height - ((@header-height - 34px)));
      padding: 3px;
      vertical-align: top;
    }
  }
}

header {
.horizontal-nav();
  & .container {
    margin: 0 auto;
    position: relative;
    max-width: 1400px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 100px;
  }
}


.vertical-nav{
  .col(12);
  margin-top: 40px;
  

  
    .header-bar-right {
      position: absolute;
      top: 0;
      right: @simple-padding;
      height: @header-right-height;
      

///// CART //////

//     .cart {
      
//       // vertical-align: middle;
//       line-height: @header-right-height;
//       height: @header-right-height;
//       float: right;
//       display: inline-block;

//         & .cart-title{
//           color: #ffffff;
//           .font(12);
//           display: inline-block;
//           vertical-align: middle;
//           float: right;
//           line-height: @header-right-height;
//           margin-right: @simple-padding;
//           height: @header-right-height;
//           // float: right;
//           // text-align: right;
//         }

//         & .glyphicon-shopping-cart{

//           display: inline-block;
//           vertical-align: middle;
//           float: right;
//           line-height: @header-right-height;
//           height: @header-right-height;
//           .icon-cart();
//             &:after{
//               line-height: @header-right-height;
//               color: #ffffff;
//               position: relative;
//               top: -20px;
//               z-index: 0;
//           }
//         }

//         .cart-item-counter {
//           position: relative;
//           display: block;
//           width: 18px;
//           height: 18px;
//           border: 1px solid #fff;
//           background-color: #9b8097;
//           border-radius: 50%;
//           color: #fff;
//           top: 3px;
//           right: -20px;
//           left: auto;
//           text-align: center;
//           padding-top: 3px;
//           font-weight: 700;
//           font-style: normal;
//           z-index: 2;
//           .font(10);
          
           
//         }
// /////////////HOVER CART////////////////////
//         &:hover {
        
//         }
//     }



///// ACCOUNT + CONNECT ////// 
   
    .account/*.dropdown*/   {
      display: inline-block;
      float: right;
      height: @header-right-height;
      line-height: @header-right-height;
      margin-left: @double-padding;
      
         
  

        & a.dropdown-toggle {
          display: inline-block;
          vertical-align: middle;
          float: right;
          .font-size(12);
          color: #ffffff;
          display: inline-block;
          vertical-align: middle;
          float: right;
          line-height: @header-right-height;
          & .avatar{
            .container(40,40);
            display: inline-block;
            padding: 0;
            .icon-avatar();

            &:after{
              font-size: 40px;
              line-height: @header-right-height;
              display: inline-block;
              vertical-align: middle;
              float: right;
              color: @blade-element-color;
            }
          }
          & .caret {
            margin-left: 5px;
            display: inline-block;
            border-color: @blade-element-color;
          }
          & span{
            display: none;
          }
        }
    }
  }



  .account .dropdown-menu {
    
    // .icon-avatar();
    // .font(12);
  }


///////////////////////// SEARCH BAR ///////////////
}

.horizontal-nav{
  .container(100%,none,auto);
  @media @mobile, @tablet{
    position: relative;
    height: auto;
  }
  z-index: 1000;
  margin: 0;
  padding: 0;
  border: 0;
  .form-search {
    width: 100%;
    margin-top: 50px;
    @media @mobile {
      margin-top: 0;
    }
      input {
      height: 50px;
      border: 1px solid #003974;
      box-shadow: none;
      background: transparent;
      @media @mobile {
        margin: 8px 0px 15px 0px;
      }
    }
    & .input-group {
      position: relative;
      display: table;
      border-collapse: separate;
    }
    & .input-group-btn {
      position: relative;
      font-size: 0;
      white-space: nowrap;
      width: 1%;
      vertical-align: middle;
      display: table-cell;
      & .btn {
        display: table-cell;
        width: 45px;
        height: 50px;
        border: 1px solid #003974;
        border-left: 0;
        margin: 5px 0 15px -1px;
        .border-radius(0);
        background: url(../img/search.png) center center no-repeat;
        @media @mobile {
          margin-top: 0;
        }
      }
    }
  }
//////////////////   ACCOUNT BLADE + CART ////////////////////
.header-bar-right {
    position: absolute;
    top: 0;
    right: @simple-padding;
    height: @header-right-height;
    

/////////////////////////////////////////////////////////////////////////////////////// CART //////

.cart {
      
      line-height: @header-right-height;
      height: @header-right-height;
      display: inline-block;

        & .cart-title{
          @media @mobile{.hide();};
          color: @font-base-color;
          .font(12);
          display: inline-block;
          vertical-align: middle;
          line-height: @header-right-height;
          margin-right: @simple-padding;
          height: @header-right-height;
          text-align: right;
        }

        & .glyphicon-shopping-cart{
          display: inline-block;
          vertical-align: middle;
          line-height: 30px;
          height: 30px;
          font-family: hollyglyph !important;
          text-transform: none;
          .icon-cart();
            &:after{
              position: relative;
              top: -5px;
              z-index: 0;
              font-size:40px;
          }
        }

        .cart-item-counter {
          position: relative;
          font-family: @client-font-family;
          display: block;
          width: 22px;
          height: 22px;
          border: 2px solid #fff;
          background-color: #2f96b5;
          border-radius: 50%;
          color: #ffffff;
          top: -8px;
          right: -32px;
          left: auto;
          text-align: center;
          padding-top: 2px;
          font-weight: 700;
          .font(10);
          z-index: 10;
          
           
        }
/////////////HOVER CART////////////////////
        &:hover {
        
        }
    }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///// ACCOUNT + CONNECT ////// 

  .account {
    display: inline-block;
    // float: right;
    // height: @header-right-height;
    line-height: @header-right-height;
    margin-left: @double-padding;  
    &:hover{
      & a.dropdown-toggle {
        & .avatar{
          &:after{
            color: @brand-primary;
            }
          }
        }
    }  

    & a.dropdown-toggle {
      display: inline-block;
      vertical-align: middle;
      float: right;
      .font-size(12);
      color: @blade-element-color;
      display: inline-block;
      vertical-align: middle;
      float: right;
      line-height: @header-right-height;
      & .avatar{
        .container(40,40);
        display: inline-block;
        .icon-avatar();
        padding: 0;

        &:after{
          font-size: 40px;
          line-height: @header-right-height;
          display: inline-block;
          vertical-align: middle;
          float: right;
          color: @blade-element-color;
        }
      }
      & .caret {
        margin-left: 5px;
        display: inline-block;
        line-height: @header-right-height;
        height: @header-right-height;
      }
      & span{
        display: none;
      }
    }
    &.open,
    &:hover {    
    }
  }
}

  .connect{
    display: inline-block;
    height: @header-right-height;
    line-height: @header-right-height;
    margin: 0 @simple-padding 0  @double-padding;
    & .btn{margin: 0;}
  }



.account/*.dropdown*/   {

      &.open,
      &:hover {
        color: @brand-primary;
        & a.dropdown-toggle {
           .caret {
            color: @brand-primary;
            }
          }
      }
      a.dropdown-toggle {
        display: inline-block;
        & > span {
          padding: 10px 0;
          display: inline-block;
        }
        .caret {
          margin-left: 5px;
        }
        &:hover,
        &:focus {
          avatar: transparent;
          text-decoration: none;
        }
      }
      .dropdown-menu {
        right: 0;
        left: auto;

        // Prevent header override
        > li > a {
          color: @dropdown-link-color;
          &:hover,
          &:focus {
            color: @dropdown-link-hover-color;
            color: @dropdown-link-hover-bg;
          }
        }
      }
      .picture {
        // .square(@header-height - ((@header-height - 34px)));
        padding: ((@header-height - 34px) / 2);
        vertical-align: top;
      }
    }
  }


///////////////////ACCOUNT /////////////////////////



  .account .dropdown-menu {
    &:before {
      position: absolute;
      top: -10px;
      right: 19px;
      display: inline-block;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #ccc;
      border-left: 10px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      content: '';
    }

    &:after {
      position: absolute;
      top: -9px;
      right: 20px;
      display: inline-block;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #ffffff;
      border-left: 9px solid transparent;
      content: '';
    }
  }



  /////////////////////////////   LANG SWITCH ///////////////////////


    .login, .lang-switch {
     > li {

      & a:hover {
        text-decoration: none;
        color: #b5b2b2;
      }
      &.active {
      }
      &.active a {
        color: @brand-primary;
        font-weight: bold;
      }
    }
  }

