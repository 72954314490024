@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Thin.woff2") format("woff2"), url("../fonts/Roboto-Thin.woff") format("woff"), url("../fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }
/* END Thin */
/* BEGIN Thin Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-ThinItalic.woff2") format("woff2"), url("../fonts/Roboto-ThinItalic.woff") format("woff"), url("../fonts/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }
/* END Thin Italic */
/* BEGIN Light */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Light.woff2") format("woff2"), url("../fonts/Roboto-Light.woff") format("woff"), url("../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }
/* END Light */
/* BEGIN Light Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-LightItalic.woff2") format("woff2"), url("../fonts/Roboto-LightItalic.woff") format("woff"), url("../fonts/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }
/* END Light Italic */
/* BEGIN Regular */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }
/* END Regular */
/* BEGIN Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Italic.woff2") format("woff2"), url("../fonts/Roboto-Italic.woff") format("woff"), url("../fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Italic.woff2") format("woff2"), url("../fonts/Roboto-Italic.woff") format("woff"), url("../fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }
/* END Italic */
/* BEGIN Medium */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Medium.woff2") format("woff2"), url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }
/* END Medium */
/* BEGIN Medium Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-MediumItalic.woff2") format("woff2"), url("../fonts/Roboto-MediumItalic.woff") format("woff"), url("../fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }
/* END Medium Italic */
/* BEGIN Bold */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto-BoldItalic.woff2") format("woff2"), url("../fonts/Roboto-BoldItalic.woff") format("woff"), url("../fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }
