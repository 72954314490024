// body{
// 	.bg-contain(@body-default-bg, top, center,@bg-default-color);
// }




// .bg-cover(@url, @color){
// 	background: url(@url) no-repeat center center; 
// 	-webkit-background-size: cover;
// 	-moz-background-size: cover;
// 	-o-background-size: cover;
// 	background-size: cover;
// 	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src=@url, sizingMethod='scale');
// 	-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src="@url", sizingMethod='scale')";
// 	background-color: @color;
// }
.bg-cover(@url, @x:center, @y:center, @bg: transparent){
	background: url(@url) no-repeat @x @y; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src=@url, sizingMethod='scale');
	-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src="@url", sizingMethod='scale')";
}

.bg-contain(@url, @x:center, @y:center, @bg: transparent){
	background: url(@url) no-repeat @x @y; 
	-webkit-background-size: contain;
	-moz-background-size: contain;
	-o-background-size: contain;
	background-size: contain;
	background-color: @bg;
}
.bg-simple(@url){
	background-image: url(@url);
	background-repeat: no-repeat;
	background-position: center center; 
}

.bg-repeat(@url){
background: url(@url) repeat center center; 
}

.bg-fixed(@url, @x:center, @y:center, @bg: transparent){
	background: url(@url) no-repeat @x @y fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src=@url, sizingMethod='scale');
	-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src="@url", sizingMethod='scale')";

}
.bg-sprite(@url,@fallback,@index){
	background: url(@url) no-repeat; 
	background: url(@fallback) no-repeat;
	background-position: 0 (@index * -16px); 
	-webkit-background-size: contain;
	-moz-background-size: contain;
	-o-background-size: contain;
	background-size: contain;
}


/////////////// A VIRER /////////////////


.cover(@height){
	margin:0;
	height: @height;
	display: table;
	vertical-align: middle;
	overflow: hidden;
	position: absolute;
	z-index: -9999;
	.container(fluid, @height);
	
		& img{
	 	min-height: @height*1px;
	 	height: auto;
	  	min-width: 320px;
	  	width: 100%;
	  	position: absolute;
	  	vertical-align: middle;
	  	display: table-cell;
		
		}

}
.text-jumbotron(@height){
	& h1,h2,h3,h4,h5,h6, p, strong {
		position: absolute;
		top:(@height/ 2)*1px;
		left:0;
		color: @text-jumbotron;
		}
}


.rgba(@r,@g,@b,@a){
	// fade(@baseColor, @opacity);
	@argb-value:argb(rgba(@r, @g, @b,@a));
	background-color: rgba(@r,@g,@b,@a);
	-ms-filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=@argb-value,endColorstr=@argb-value);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=@argb-value,endColorstr=@argb-value);
	zoom: 1;

}
